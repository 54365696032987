import { useState, useContext, createContext, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useUserState } from "./UserState";
import {
  OrganizationSettings,
  InvitedUser,
  UserType,
  EvaluationFormType,
  SignalType,
  dateRange,
} from "src/utils/types";
import { settingsConverter } from "src/utils/converter";
import { LOCALSTORAGE_TYPES } from "src/utils/enums";
import { getRecordFromFireStore } from "src/firebaseAuth";

const OrganizationContext = createContext({
  organization: null,
  setOrganization: null,
  allExistingOrganizations: null,
  setAllExistingOrganizations: null,
  transcripts: null,
  setTranscripts: null,
  insights: null as {
    filterDates: dateRange;
    organization: string;
    allInsightsData: any;
    selectedDateInsights: any;
    loading: boolean;
    showFilterTranscripts: boolean;
  },
  setInsights: null,
  playlists: null,
  setPlaylists: null,
  customers: null,
  setCustomers: null,
  analysisStructures: null,
  setAnalysisStructures: null,
  organizationSettings: null as OrganizationSettings,
  setOrganizationSettings: null,
  evaluationForms: null as EvaluationFormType[],
  setEvaluationForms: null,
  signals: null as SignalType[],
  setSignals: null,
  collaborators: null as UserType[],
  setCollaborators: null,
  pendingCollaborators: null as InvitedUser[],
  setPendingCollaborators: null,
});

const defaultOrganizationSettings: OrganizationSettings = {
  showRisk: false,
  showSentiment: true,
  emailConversationSummary: false,
  teams: [],
  conversationProperties: [],
  name: "",
  customerStages: [],
  stripe: null,
  numberOfSeats: 0,
};

export const OrganizationProvider = ({ children }) => {
  const [organization, setOrganization] = useState(null);
  const [allExistingOrganizations, setAllExistingOrganizations] =
    useState(null);
  const [transcripts, setTranscripts] = useState(null);
  const [insights, setInsights] = useState(null);
  const [customers, setCustomers] = useState<{
    data: any;
    hasMore: boolean;
    lastDoc: any;
  }>({
    data: null,
    hasMore: false,
    lastDoc: null,
  });
  const [playlists, setPlaylists] = useState<{
    data: any;
    hasMore: boolean;
    lastDoc: any;
  }>({
    data: null,
    hasMore: false,
    lastDoc: null,
  });
  const organizationSettingsLS = localStorage.getItem(
    LOCALSTORAGE_TYPES.ORGANIZATION_SETTINGS,
  );
  const [organizationSettings, setOrganizationSettings] =
    useState<OrganizationSettings>(
      organizationSettingsLS
        ? JSON.parse(organizationSettingsLS)
        : defaultOrganizationSettings,
    );
  const [analysisStructures, setAnalysisStructures] = useState(null);
  const [evaluationForms, setEvaluationForms] = useState(
    null as EvaluationFormType[],
  );
  const [signals, setSignals] = useState(null as SignalType[]);
  const [collaborators, setCollaborators] = useState(null as UserType[]);
  const [pendingCollaborators, setPendingCollaborators] = useState(
    null as InvitedUser[],
  );

  const { user } = useUserState();
  useEffect(() => {
    if (user) {
      setOrganization(user.organization);
    }
  }, [user]);

  useEffect(() => {
    const fetchAndUpdateOrganizationSettings = async () => {
      if (!organization) return;

      const fetchedOrganizationSettings = await getRecordFromFireStore(
        `organization/${organization}`,
        settingsConverter(organization),
        defaultOrganizationSettings,
      );

      if (
        fetchedOrganizationSettings &&
        !isEqual(fetchedOrganizationSettings, organizationSettings)
      ) {
        localStorage.setItem(
          LOCALSTORAGE_TYPES.ORGANIZATION_SETTINGS,
          JSON.stringify(fetchedOrganizationSettings),
        );
        setOrganizationSettings(fetchedOrganizationSettings);
      }
    };
    fetchAndUpdateOrganizationSettings();
  }, [organization, organizationSettings]);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        setOrganization,
        allExistingOrganizations,
        setAllExistingOrganizations,
        transcripts,
        setTranscripts,
        insights,
        setInsights,
        playlists,
        setPlaylists,
        customers,
        setCustomers,
        analysisStructures,
        setAnalysisStructures,
        organizationSettings,
        setOrganizationSettings,
        evaluationForms,
        setEvaluationForms,
        signals,
        setSignals,
        collaborators,
        setCollaborators,
        pendingCollaborators,
        setPendingCollaborators,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationState = () => useContext(OrganizationContext);
