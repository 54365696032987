import { CardMetaContainer, UserProfileAvatar } from "../Profile.styles";
import { BorderlessCard, CollaboratorAvatar } from "src/components";
import { ACCESS_TYPE } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import { Card, Space, Spin, Statistic } from "antd";
import { useUserState } from "src/state/UserState";
import { OrganizationSelect } from "src/components/Filters/OrganizationSelect";
export const ProfileCard = () => {
  const { user, userStatistics } = useUserState();
  const { organizationSettings } = useOrganizationState();
  return (
    <Card style={{ justifyContent: "center", display: "flex" }}>
      <CardMetaContainer
        avatar={
          <CollaboratorAvatar
            key={user?.name}
            collaborator={user}
            AvatarComponent={UserProfileAvatar}
          />
        }
        title={user.name}
        description={
          user?.accessType === ACCESS_TYPE.trucoAdmin ? (
            <OrganizationSelect type="profile" />
          ) : (
            organizationSettings?.name
          )
        }
      />

      <Spin spinning={!userStatistics}>
        <BorderlessCard>
          <Space wrap size={"large"} style={{ justifyContent: "center" }}>
            {userStatistics?.map((item, idx) => {
              return (
                <Card key={idx}>
                  <Statistic title={item.title} value={item.value} />
                </Card>
              );
            })}
          </Space>
        </BorderlessCard>
      </Spin>
    </Card>
  );
};
