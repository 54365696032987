import { Badge, Card, List, Popover, Switch } from "antd";
import { AddRemoveTags } from "src/components";
import { color } from "src/styles/variables";

// we should update this on what is new and remove it when it's no longer new,
// we can later do it in Firestore to avoid having to deploy when we want to add or remove
const currentNewKeys = ["emailConversationSummary"];

export const SettingComponent = ({
  settingsKey,
  title,
  description,
  hasAccessToEdit,
  onChange,
  tags = null,
  tagType,
  switchValue = null,
}) => {
  return (
    <>
      {currentNewKeys.includes(settingsKey) && (
        <Badge.Ribbon
          text="New"
          placement="start"
          color={color.orange}
        ></Badge.Ribbon>
      )}
      <Card.Grid
        style={{
          width: "100%",
        }}
        hoverable={false}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <List.Item style={{ width: "65%", textAlign: "start" }}>
            <List.Item.Meta title={title} description={description} />
          </List.Item>
          <Popover
            content={
              !hasAccessToEdit
                ? "Only owners/admins have access to change setting"
                : ""
            }
          >
            {tags !== null && (
              <AddRemoveTags
                tagType={tagType}
                tags={tags}
                onChange={(newValue) => onChange(newValue)}
                style={{ justifyContent: "flex-end" }}
              />
            )}
            {switchValue !== null && (
              <Switch
                checked={switchValue}
                onChange={() => onChange(!switchValue)}
                disabled={!hasAccessToEdit}
              />
            )}
          </Popover>
        </div>
      </Card.Grid>
    </>
  );
};
