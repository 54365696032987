import React from "react";
import { Input, Button, Form } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { color } from "src/styles/variables";

interface EditableListProps {
  items: string[];
  setItems: (items: string[]) => void;
  placeholder: string;
  disabled: boolean;
}

const EditableList: React.FC<EditableListProps> = ({
  items,
  setItems,
  placeholder,
  disabled,
}) => {
  const handleAddItem = () => {
    setItems([...items, ""]);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleItemChange = (index: number, value: string) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  return (
    <>
      {items.map((item, index) => (
        <Form.Item key={index}>
          <Input
            value={item}
            onChange={(e) => handleItemChange(index, e.target.value)}
            placeholder={`${placeholder} ${index + 1}`}
            disabled={disabled}
            style={{
              width: `${disabled ? "100%" : "calc(100% - 32px)"}`,
              marginRight: "8px",
            }}
          />
          {!disabled && (
            <MinusCircleOutlined
              onClick={() => handleRemoveItem(index)}
              style={{ cursor: "pointer", color: color.orange }}
            />
          )}
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="dashed" onClick={handleAddItem} disabled={disabled}>
          Add {placeholder}
        </Button>
      </Form.Item>
    </>
  );
};

export default EditableList;
