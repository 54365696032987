import { createOrUpdateDocInFirestore } from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { humanize, unhumanize } from "src/utils/utils";
import { SettingComponent } from "./SettingComponent";
import { SETTINGS_DESCRIPTION } from "../../Profile.enums";
import { tagTypeMapping } from "../../Profile.constants";

export const OrganizationSettingComponent = ({
  settingsKey,
  hasAccessToEdit,
  setLoadingSetting,
}) => {
  const { organization, organizationSettings, setOrganizationSettings } =
    useOrganizationState();

  const updateSetting = async (settingName: string, newValue) => {
    if (settingName === "customerStages") {
      newValue = newValue.map((stage) => stage.toLowerCase());
    }
    setOrganizationSettings((prevSettings) => {
      // @todo persist this in db as well!
      return { ...prevSettings, [settingName]: newValue };
    });
    setLoadingSetting(true);
    await createOrUpdateDocInFirestore(`/organization/${organization}`, {
      [settingName]: newValue,
    });
    setTimeout(() => {
      setLoadingSetting(false);
    }, 500);
  };

  let tags,
    switchValue = null;

  if (!settingsKey || ["name"].includes(settingsKey)) return null;
  else if (
    ["teams", "conversationProperties", "customerStages"].includes(settingsKey)
  ) {
    tags = organizationSettings[settingsKey].map((property) =>
      humanize(property),
    );
  } else {
    switchValue = organizationSettings[settingsKey];
  }

  return (
    <SettingComponent
      settingsKey={settingsKey}
      hasAccessToEdit={hasAccessToEdit}
      title={settingsKey
        .replace(/([A-Z])/g, " $1") // insert a space before all caps
        .replace(/^./, (str) => str.toUpperCase()) // capitalize the first letter
        .trim()}
      description={SETTINGS_DESCRIPTION[settingsKey]}
      tags={tags}
      tagType={tagTypeMapping[settingsKey]}
      switchValue={switchValue}
      onChange={async (newValue) => {
        const updatedValue =
          settingsKey === "conversationProperties"
            ? newValue.map((property) => unhumanize(property))
            : newValue;
        await updateSetting(settingsKey, updatedValue);
      }}
    />
  );
};
