import { elementSize, spacing } from "src/styles/variables";
import styled from "styled-components";

export const CustomerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CustomerInfoContainer = styled.div`
  display: flex;
  margin-bottom: ${spacing.xl},
  align-items: center;
  vertical-align: center;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const IntegrationContainer = styled.div`
  margin-top: ${spacing.md};
  display: flex;
  flex-direction: column;
  gap: ${elementSize.md};
`;
