import { RATINGS, CONDITION_TYPES } from "src/utils/enums";
import {
  EvaluationFormData,
  AnalysisStructure,
  AnalysisStructureValue,
  SignalType,
} from "src/utils/types";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import unset from "lodash/unset";
import { Menu, Popconfirm } from "antd";
import { EditFilled, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
export const createNewMetric = (
  id = "",
  label = "",
  value = null,
): AnalysisStructureValue => ({
  id: id,
  label: label,
  value: value || {
    // description: "",
    type: "string",
    knowledge: "",
    conditions: [
      {
        condition: "",
        value: "",
        rating: RATINGS.NONE,
        type: CONDITION_TYPES.RETURN,
      },
    ],
  },
});

export const createNewCategory = (): AnalysisStructure => ({
  id: "",
  label: "",
  weight: 1,
  value: [createNewMetric()],
});

export const createNewCategoryWithSubCategories = (category) => {
  return {
    id: category.id,
    label: category.label,
    value: category.value.map((subCategory) =>
      createNewMetric(subCategory.id, subCategory.label, subCategory.value),
    ),
    weight: category.weight || 1,
  };
};

export const handleAddCategoryWithSubCategories = (
  analysisStructure,
  setAnalysisStructures,
  newCategory,
) => {
  const updatedCategories = [...analysisStructure];

  // Check if this is the first category with an id and place it at the beginning
  if (!analysisStructure.some((category) => category.id)) {
    updatedCategories.unshift(newCategory);
  } else {
    updatedCategories.push(newCategory);
  }

  setAnalysisStructures(updatedCategories);
  const newCategoryId = `category-${updatedCategories.length - 1}`;

  setTimeout(() => {
    document
      .getElementById(newCategoryId)
      ?.scrollIntoView({ behavior: "smooth" });
  }, 0);
};

export const createNewEvaluationForm = (
  evaluationTitle: string = "",
  evaluationDescription: string = "",
): EvaluationFormData => ({
  title: evaluationTitle,
  description: evaluationDescription,
  teams: [],
  analysisStructures: [createNewCategory()],
  deleted: false,
  sentiment: false,
  version: 1,
});

export const removeError = (errorKey, errors, setErrors) => {
  if (errors.hasOwnProperty(errorKey)) {
    // Create a copy of the errors object
    const newErrors = { ...errors };
    // Delete the specific error
    if (newErrors[errorKey]) {
      delete newErrors[errorKey];
      setErrors(newErrors);
    }
  }
};

export const handleDelete = (path, index, setCurrentSelectedEvaluation) => {
  setCurrentSelectedEvaluation((prevState) => {
    const newState = cloneDeep(prevState);
    const arrayAtPath = get(newState, path);
    const updatedArray = arrayAtPath.filter(
      (_, itemIndex) => itemIndex !== index,
    );
    set(newState, path, updatedArray);
    return newState;
  });
};

export const handleAddCategory = (analysisStructure, setAnalysisStructures) => {
  const updatedCategories = [...analysisStructure];
  updatedCategories.push(createNewCategory());
  setAnalysisStructures(updatedCategories);
  const newCategoryId = `category-${updatedCategories.length - 1}`;

  setTimeout(() => {
    document
      .getElementById(newCategoryId)
      ?.scrollIntoView({ behavior: "smooth" });
  }, 0);
};
export const handleAddSubCategory = (
  categoryIndex,
  analysisStructure,
  setAnalysisStructures,
) => {
  const updatedCategories = [...analysisStructure];
  updatedCategories[categoryIndex].value.push(createNewMetric());
  setAnalysisStructures(updatedCategories);
  const newSubCategoryId = `subcategory-${categoryIndex}-${updatedCategories[categoryIndex].value.length - 1}`;

  setTimeout(() => {
    document
      .getElementById(newSubCategoryId)
      ?.scrollIntoView({ behavior: "smooth" });
  }, 0);
};
export const addItemToExamples = (
  path,
  newItem,
  setCurrentSelectedEvaluation,
) => {
  setCurrentSelectedEvaluation((prevState) => {
    const newState = cloneDeep(prevState);
    const existingArray = get(newState, path, []);
    existingArray.push(newItem);
    set(newState, path, existingArray);
    return newState;
  });
};

export const removeItemFromExamples = (
  path,
  index,
  setCurrentSelectedEvaluation,
) => {
  setCurrentSelectedEvaluation((prevState) => {
    const newState = cloneDeep(prevState);
    const array = get(newState, path, []);
    if (index >= 0 && index < array.length) {
      array.splice(index, 1);
      set(newState, path, array);
    }
    return newState;
  });
};

export const handleChangeInEvaluation = (
  path,
  value,
  setCurrentSelectedEvaluation,
  errorPath?,
  errors?,
  setErrors?,
) => {
  setCurrentSelectedEvaluation((prevState) => {
    if (get(prevState, path) !== value) {
      const newState = cloneDeep(prevState);

      const RatingRegex =
        /analysisStructures\[(\d+)]\.value\[(\d+)]\.value\.conditions\[(\d+)]\.rating$/;
      const TypeRegex =
        /analysisStructures\[(\d+)]\.value\[(\d+)]\.value\.conditions\[(\d+)]\.type$/;
      const ValueRegex =
        /analysisStructures\[(\d+)]\.value\[(\d+)]\.value\.conditions\[(\d+)]\.value$/;

      const matchType = path.match(TypeRegex);
      const ratingMatch = path.match(RatingRegex);
      const matchValue = path.match(ValueRegex);

      if (ratingMatch && value !== undefined) {
        const [categoryIndex, subCategoryIndex, conditionIndex] = ratingMatch
          .slice(1)
          .map(Number);
        const conditionsPath = `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions`;
        const conditions = get(newState, conditionsPath, []);

        if (conditions[conditionIndex]?.type === CONDITION_TYPES.EXTRACT) {
          conditions.forEach((condition, idx) => {
            if (condition.type === CONDITION_TYPES.EXTRACT) {
              set(newState, `${conditionsPath}[${idx}].rating`, value);
            }
          });
        } else {
          set(newState, path, value);
        }
      } else if (matchType && value !== undefined) {
        const [categoryIndex, subCategoryIndex, conditionIndex] = matchType
          .slice(1)
          .map(Number);
        const conditionsPath = `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions`;
        const examplesPath = `${conditionsPath}[${conditionIndex}].examples`;

        set(newState, `${conditionsPath}[${conditionIndex}].type`, value);
        const examples = get(newState, examplesPath, []);

        if (value === CONDITION_TYPES.RETURN) {
          examples.forEach((_, idx) => {
            set(
              newState,
              `${examplesPath}[${idx}].value`,
              get(newState, `${conditionsPath}[${conditionIndex}].value`),
            );
          });
        }
        if (value === CONDITION_TYPES.EXTRACT) {
          examples.forEach((_, idx) => {
            set(newState, `${examplesPath}[${idx}].value`, "");
          });
          // adjust rating to the first extract value found in conditions
          const conditions = get(newState, conditionsPath, []);
          const extractCondition = conditions.find(
            (condition) => condition.type === CONDITION_TYPES.EXTRACT,
          );
          if (extractCondition) {
            set(
              newState,
              `${conditionsPath}[${conditionIndex}].rating`,
              extractCondition.rating,
            );
          }
        }
      } else if (matchValue && value !== undefined) {
        const [categoryIndex, subCategoryIndex, conditionIndex] = matchValue
          .slice(1)
          .map(Number);
        const conditionsPath = `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions`;
        const condition = get(newState, `${conditionsPath}[${conditionIndex}]`);

        if (condition.type === CONDITION_TYPES.RETURN) {
          const examplesPath = `${conditionsPath}[${conditionIndex}].examples`;
          const examples = get(newState, examplesPath, []);
          examples.forEach((_, idx) => {
            set(newState, `${examplesPath}[${idx}].value`, value);
          });
        }

        set(newState, path, value);
      } else {
        if (value !== undefined) {
          set(newState, path, value);
        } else {
          unset(newState, path);
        }
      }

      if (errorPath) {
        removeError(errorPath, errors, setErrors);
      }

      return newState;
    }
    return prevState;
  });
};

export const handleAddConditionalCondition = (
  categoryIndex,
  subcategoryIndex,
  setCurrentSelectedEvaluation,
  rattingValue,
) => {
  setCurrentSelectedEvaluation((prevState) => {
    const newState = cloneDeep(prevState);
    const newCondition = {
      condition: "",
      value: "",
      type: CONDITION_TYPES.RETURN,
      rating: rattingValue,
    };
    newState.analysisStructures[categoryIndex].value[
      subcategoryIndex
    ].value.conditions.push(newCondition);
    return newState;
  });
};

export const validateEvaluationFields = (
  currentSelectedEvaluation,
  analysisStructure,
  setErrors?,
) => {
  let newErrors = {};
  let isValid = true;

  if (
    !currentSelectedEvaluation.title ||
    !currentSelectedEvaluation.title.trim()
  ) {
    isValid = false;
    newErrors["evaluation-title"] = "Evaluation title is required";
  }

  if (
    !currentSelectedEvaluation.description ||
    !currentSelectedEvaluation.description.trim()
  ) {
    isValid = false;
    newErrors["evaluation-description"] = "Evaluation knowledge is required";
  }

  analysisStructure.forEach((category, categoryIndex) => {
    if (!category.label.trim()) {
      isValid = false;
      newErrors[`category-${categoryIndex}`] = "Category name is required";
    }

    category.value.forEach((subCategory, subCategoryIndex) => {
      if (!subCategory.label.trim()) {
        isValid = false;
        newErrors[`subcategory-${categoryIndex}-${subCategoryIndex}`] =
          "Metric name is required";
      }

      subCategory.value.conditions.forEach(
        (conditionStructure, conditionStructureIndex) => {
          if (!conditionStructure.condition.trim()) {
            isValid = false;
            newErrors[
              `conditionStructure-${categoryIndex}-${subCategoryIndex}-${conditionStructureIndex}`
            ] = "Condition is required";
          }

          if (!conditionStructure.value.trim()) {
            isValid = false;
            newErrors[
              `conditionStructureValue-${categoryIndex}-${subCategoryIndex}-${conditionStructureIndex}`
            ] = "Value is required";
          }
          if (conditionStructure?.examples?.length > 0) {
            conditionStructure.examples.forEach((example, exampleIndex) => {
              if (!`${example.value}`.trim()) {
                isValid = false;
                newErrors[
                  `conditionExampleStructure-${categoryIndex}-${subCategoryIndex}-${conditionStructureIndex}-${exampleIndex}`
                ] = "Example extract value is required";
              }
            });
          }
        },
      );
    });
  });

  setErrors && setErrors(newErrors);
  return isValid;
};

// BEAR in mind Categories should have metrics inside of them and shouldn't have decription as  decription
// is neither saved in Categories or in Metrics so see how u want to structure them
export const preDefinedCategories = [
  {
    id: "meddpicc",
    label: "MEDDPICC",
    description:
      "MEDDPICC is a sales qualification framework used in complex B2B sales processes. It stands for Metrics, Economic Buyer, Decision Criteria, Decision Process, Paper Process, Identify Pain, Champion, and Competition.",
    value: [
      {
        id: "metrics",
        label: "Metrics",
        value: {
          type: "string",
          knowledge:
            "Metrics refer to the quantifiable measures of value or success that the customer aims to achieve. These can include financial metrics like ROI, revenue increase, or cost savings, as well as operational metrics such as efficiency improvements or time saved. Metrics provide concrete, measurable goals for the proposed solution.",
          conditions: [
            {
              rating: 1,
              condition: "metrics are discussed",
              type: "extract",
              value: "metrics",
            },
            {
              rating: 0,
              condition: "metrics are not discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "economic_buyer",
        label: "Economic Buyer",
        value: {
          type: "string",
          knowledge:
            "The Economic Buyer is the individual within the customer's organization who has the financial authority to approve the purchase. This person typically holds a senior position and has control over the budget. The Economic Buyer may not be involved in day-to-day discussions but has the final say in the purchasing decision.",
          conditions: [
            {
              rating: 1,
              condition: "economic buyer is identified",
              type: "extract",
              value: "the economic buyer",
            },
            {
              rating: 0,
              condition: "economic buyer is not identified",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "decision_criteria",
        label: "Decision Criteria",
        value: {
          type: "string",
          knowledge:
            "Decision Criteria encompass the factors and standards that the customer will use to evaluate and choose a solution. These can include product features, pricing, implementation requirements, vendor reputation, after-sales support, and alignment with company strategy. Decision Criteria represent the formal and informal benchmarks against which all options are measured.",
          conditions: [
            {
              rating: 1,
              condition: "decision criteria are discussed",
              type: "extract",
              value: "decision criteria",
            },
            {
              rating: 0,
              condition: "decision criteria are not discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "decision_process",
        label: "Decision Process",
        value: {
          type: "string",
          knowledge:
            "The Decision Process refers to the series of steps and procedures the customer organization follows to reach a purchase decision. This includes identifying who is involved at each stage, what approvals are required, how information is shared and evaluated, and the typical timeline for moving from initial consideration to final decision.",
          conditions: [
            {
              rating: 1,
              condition: "Decision Process is discussed",
              type: "extract",
              value: "decision process",
            },
            {
              rating: 0,
              condition: "Decision Process is not discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "paper_process",
        label: "Paper Process",
        value: {
          type: "string",
          knowledge:
            "The Paper Process relates to the formal documentation and administrative procedures required to complete a purchase. This includes contract negotiations, legal reviews, procurement policies, and any other paperwork or bureaucratic processes that must be navigated to finalize the deal. Understanding the Paper Process helps anticipate potential delays or complications in the final stages of a sale.",
          conditions: [
            {
              rating: 1,
              condition: "paper process is discussed",
              type: "extract",
              value: "paper_process",
            },
            {
              rating: 0,
              condition: "paper process is not discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "identify_pain",
        label: "Identify Pain",
        value: {
          type: "string",
          knowledge:
            "Identify Pain refers to the specific challenges, issues, or inefficiencies that the customer is experiencing. These pain points are the problems that the proposed solution aims to address. They can be operational issues, financial pressures, competitive threats, or any other significant difficulties that are impacting the customer's business negatively.",
          conditions: [
            {
              rating: 1,
              condition: "pain points are discussed",
              type: "extract",
              value: "pain",
            },
            {
              rating: 0,
              condition: "pain points are not discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "champion",
        label: "Champion",
        value: {
          type: "string",
          knowledge:
            "A Champion is an individual within the customer's organization who believes in the proposed solution and advocates for its adoption. This person typically has influence within their organization and can help navigate internal politics, provide insider information, and promote the solution to other stakeholders. The Champion plays a crucial role in building internal consensus and moving the sale forward.",
          conditions: [
            {
              rating: 1,
              condition: "champion is identified",
              type: "extract",
              value: "champion",
            },
            {
              rating: 0,
              condition: "champion is not identified",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "competition",
        label: "Competition",
        value: {
          type: "string",
          knowledge:
            "Competition refers to other vendors or solutions that the customer is considering as alternatives. This includes direct competitors offering similar products or services, as well as different approaches to solving the customer's problem. Understanding the competitive landscape helps in positioning the proposed solution effectively and addressing potential objections or comparisons that may arise during the sales process.",
          conditions: [
            {
              rating: 1,
              condition: "any competitors are discussed",
              type: "extract",
              value: "competition",
            },
            {
              rating: 0,
              condition: "no competitors are mentioned",
              type: "return",
              value: "0",
            },
          ],
        },
      },
    ],
    weight: 1,
  },
  {
    id: "next_steps",
    label: "Next Steps",
    description:
      "Key information and actions to remember following the sales call",
    value: [
      {
        id: "action_items",
        label: "Action Items",
        value: {
          type: "string",
          knowledge:
            "Specific tasks or deliverables agreed upon during the call, including deadlines and responsible parties. These are concrete steps to move the sales process forward.",
          conditions: [
            {
              rating: 1,
              condition: "action items are discussed",
              type: "extract",
              value: "action items",
            },
            {
              rating: 0,
              condition: "action items are not discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "rapport_building",
        label: "Rapport Building",
        value: {
          type: "string",
          knowledge:
            "Personal or professional details about the prospect that can be used to build a stronger relationship, such as shared interests, background, or recent events.",
          conditions: [
            {
              rating: 1,
              condition: "any personal or professional details are discussed",
              type: "extract",
              value: "details",
            },
            {
              rating: 0,
              condition: "no rapport building details are discussed",
              type: "return",
              value: "0",
            },
          ],
        },
      },
      {
        id: "key_concerns",
        label: "Key Concerns",
        value: {
          type: "string",
          knowledge:
            "Primary objections or hesitations expressed by the prospect during the call that need to be addressed in future interactions.",
          conditions: [
            {
              rating: 1,
              condition: "key concerns with going forward are expressed",
              type: "extract",
              value: "key concerns",
            },
            {
              rating: 0,
              condition: "no concerns are brought up",
              type: "return",
              value: "0",
            },
          ],
        },
      },
    ],
  },
];

export const CommonMenu = ({
  onEdit,
  onDuplicate,
  onDelete,
  ComponentName,
}) => {
  const playlistCondition = ComponentName.toLowerCase().includes("signal")
    ? "By Deleting this you will also delete it's playlist?"
    : "";
  return (
    <Menu>
      <Menu.Item key="edit" icon={<EditFilled />} onClick={onEdit}>
        Edit {ComponentName}
      </Menu.Item>
      <Menu.Item key="duplicate" icon={<CopyOutlined />} onClick={onDuplicate}>
        Duplicate & Edit
      </Menu.Item>
      <Popconfirm
        title={`Delete ${ComponentName}`}
        description={
          <span>
            {`Are you sure you want to delete this ${ComponentName} form?`}
            <br />
            {playlistCondition}
          </span>
        }
        onConfirm={onDelete}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <Menu.Item key="delete" icon={<DeleteOutlined />}>
          Delete
        </Menu.Item>
      </Popconfirm>
    </Menu>
  );
};
export const createNewSignal = (): SignalType => {
  return {
    keywords: [],
    tags: [],
    phrases: [],
    title: "",
    description: "",
    id: null,
    deleted: false,
  };
};
