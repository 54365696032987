import React, { useEffect, useState } from "react";
import { Badge, Tooltip, Col, Table, Typography } from "antd";
import { Chart } from "react-google-charts";
import {
  EditIconContainer,
  PlaylistImage,
  PlaylistImageContainer,
  PlaylistVideoThumbnail,
} from "./Playlist.styles";
import {
  EditOutlined,
  EyeOutlined,
  WifiOutlined,
  CaretUpOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import { useOrganizationState } from "src/state/OrganizationState";
import { color } from "src/styles/variables";
import {
  generatePlaylistShareableLink,
  getThumbnailSource,
  hasEditAccess,
  hasPlaylistAccess,
} from "./Playlist.utils";
import { useNavigate } from "react-router-dom";
import { useUserState } from "src/state/UserState";
import { PLAYLIST_TYPE } from "src/utils/enums";

export const PlaylistImageCard = ({ playlist }) => {
  const { organization } = useOrganizationState();
  const { user } = useUserState();
  const navigate = useNavigate();
  const [loadingVideoThumbnail, setLoadingVideoThumbnail] = useState(true);
  const [thumbnail, setThumbnail] = useState<{ type: string; src: string }>({
    type: "image",
    src: "/playlist.jpeg",
  });
  const [thumbnailFetched, setThumbnailFetched] = useState(false);

  const hasAccess = hasPlaylistAccess(user, organization, playlist);
  const clipsWithAccess = playlist?.clips?.filter(
    (clip) =>
      (playlist.type === PLAYLIST_TYPE.SIGNAL &&
        clip.participants?.includes(user?.email)) ||
      hasAccess,
  );

  useEffect(() => {
    const fetchThumbnail = async () => {
      const thumbnailSource = await getThumbnailSource(playlist);
      setThumbnail(thumbnailSource);
      setThumbnailFetched(true);
    };
    fetchThumbnail();
  }, [playlist]);

  useEffect(() => {
    if (thumbnailFetched) {
      setLoadingVideoThumbnail(false);
    }
  }, [thumbnailFetched]);

  return (
    <Badge.Ribbon text={`${clipsWithAccess?.length} clips`}>
      <PlaylistImageContainer
        onClick={() =>
          navigate(
            `${generatePlaylistShareableLink(organization, playlist.id)}`,
          )
        }
      >
        {thumbnail.type.includes("video") ? (
          <PlaylistVideoThumbnail
            src={thumbnail.src}
            onLoadedData={() => setLoadingVideoThumbnail(false)}
            style={{ display: loadingVideoThumbnail ? "none" : "block" }}
          />
        ) : (
          <PlaylistImage alt="playlist" src={thumbnail.src} />
        )}

        <EditIconContainer>
          {playlist.type !== PLAYLIST_TYPE.SIGNAL && (
            <>
              {hasEditAccess(playlist, user) ? (
                <EditOutlined
                  style={{
                    color: color.white,
                  }}
                />
              ) : (
                <EyeOutlined
                  style={{
                    color: color.white,
                  }}
                />
              )}
            </>
          )}
          {playlist.type === PLAYLIST_TYPE.SIGNAL && (
            <Tooltip title="Signal Playlist">
              <WifiOutlined
                style={{
                  color: color.white,
                }}
              />
            </Tooltip>
          )}
        </EditIconContainer>
      </PlaylistImageContainer>
    </Badge.Ribbon>
  );
};

export const SignalCard = ({
  playlists,
  setLoading,
  spanValue = 24,
  height = 50,
}) => {
  const navigate = useNavigate();
  const { organization } = useOrganizationState();
  const currentMonth = new Date().getMonth();
  const lastMonth = currentMonth - 1;
  const lastMonthDays = new Date(
    new Date().getFullYear(),
    lastMonth + 1,
    0,
  ).getDate();
  const currentMonthDays = new Date().getDate();

  const signalPlaylists = playlists?.filter(
    (playlist) => playlist?.type === "signal",
  );

  const columns = [
    {
      title: "Signal",
      key: "signal",
      render: (_, playlist) => (
        <>
          {/* <PlaylistImageCard playlist={playlist} /> */}
          <strong>{playlist?.title?.toUpperCase()}</strong>
          <p>{playlist?.description}</p>
        </>
      ),
    },
    {
      title: "Trend",
      key: "trend",
      width: "30%",
      render: (_, playlist) => {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        // Create an array with the last 30 days
        const last30Days = Array.from({ length: 30 }, (_, i) => {
          const date = new Date(thirtyDaysAgo);
          date.setDate(date.getDate() + i);
          return date.toISOString().split("T")[0];
        });

        const clipCounts = playlist.clips.reduce((acc, clip) => {
          const clipDate = new Date(clip.updatedAt).toISOString().split("T")[0];
          if (clipDate >= last30Days[0]) {
            acc[clipDate] = (acc[clipDate] || 0) + 1;
          }
          return acc;
        }, {});

        const aggregatedData = last30Days.map((date) => [
          date,
          clipCounts[date] || 0,
        ]);

        if (!aggregatedData?.length) return null;

        const chartData = [["Date", "Clips"], ...aggregatedData];

        return (
          <Chart
            width="200px"
            height="50px"
            chartType="ColumnChart"
            loader={<div>Loading Chart...</div>}
            data={chartData}
            options={{
              hAxis: {
                textPosition: "none",
                gridlines: { color: "transparent" },
                baselineColor: "transparent",
                ticks: [],
              },
              vAxis: {
                textPosition: "none",
                gridlines: { color: "transparent" },
                baselineColor: "transparent",
                ticks: [],
              },
              legend: { position: "none" },
              axisTitlesPosition: "none",
              chartArea: {
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
              },
              bar: { groupWidth: "85%" },
              tooltip: { trigger: "hover" },
              backgroundColor: "transparent",
              colors: [color.orange],
              animation: { startup: true, duration: 1000, easing: "out" },
            }}
          />
        );
      },
    },
    {
      title: "Volume (Percentage Change)",
      key: "percentageChange",
      render: (_, playlist) => {
        const currentMonthClips = playlist.clips.filter(
          (clip) => new Date(clip.updatedAt).getMonth() === currentMonth,
        ).length;
        const lastMonthClips = playlist.clips.filter(
          (clip) => new Date(clip.updatedAt).getMonth() === lastMonth,
        ).length;

        const lastMonthAvgClipsPerDay = lastMonthClips / lastMonthDays;
        const currentMonthAvgClipsPerDay = currentMonthClips / currentMonthDays;

        let percentageChange = 0; // Default to 0 if last month's avg clips per day is 0

        if (lastMonthAvgClipsPerDay > 0) {
          percentageChange = Math.round(
            ((currentMonthAvgClipsPerDay - lastMonthAvgClipsPerDay) /
              lastMonthAvgClipsPerDay) *
              100,
          );
        } else if (currentMonthAvgClipsPerDay > 0) {
          percentageChange = 100;
        }

        return (
          <>
            <Typography.Text>{playlist.clips.length} (</Typography.Text>
            <Typography.Text
              type={percentageChange >= 0 ? "success" : "danger"}
            >
              {percentageChange >= 0 ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownFilled />
              )}
              {Math.abs(percentageChange)}%
            </Typography.Text>
            <Typography.Text>)</Typography.Text>
          </>
        );
      },
    },
  ];

  return (
    <Col xl={{ span: spanValue }} md={24}>
      <Table
        dataSource={signalPlaylists}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              `${generatePlaylistShareableLink(organization, record.id)}`,
            );
          },
        })}
      />
    </Col>
  );
};
