import React, { useEffect, useState } from "react";
import {
  Spin,
  Button,
  message,
  Steps,
  Input,
  Form,
  Checkbox,
  List,
  Row,
  Col,
} from "antd";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { DeskTopOnly } from "src/styles/stylingComponents";
import { ArrowLeftOutlined, EyeOutlined } from "@ant-design/icons";
import { color, elementSize, fontWeight } from "src/styles/variables";
import {
  contentStyle,
  handleCheckboxChange,
  handleEyeClick,
  sampleDefaultEvaluations,
  steps,
  validateOrganizationName,
} from "./ManageOrganization.utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { EditOutlined } from "@mui/icons-material";
import { EvaluationFormData } from "src/utils/types";
import { SampleEvaluationModal } from "./SampleEvalutionModal";
import {
  addDocumentWithId,
  getRecordsFromFireStore,
  updateDocInFireStore,
} from "src/firebaseAuth";
import { COLLECTION_DATA } from "src/utils/enums";
import { TRUCO_ORGANIZATION_ID } from "src/utils/variables";
import { SampleSignalModal } from "./SampleSignalModel";

const fetchEvaluationsAndSignals = async () => {
  try {
    const QueryConditions = [
      { comparisonField: "deleted", comparisonOperator: "==", value: false },
    ];
    const evaluationsResult = await getRecordsFromFireStore(
      `${COLLECTION_DATA.ORGANIZATIONS}/${TRUCO_ORGANIZATION_ID}/${COLLECTION_DATA.EVALUATION_FORMS}`,
      QueryConditions,
    );

    const signalsResult = await getRecordsFromFireStore(
      `${COLLECTION_DATA.ORGANIZATIONS}/${TRUCO_ORGANIZATION_ID}/${COLLECTION_DATA.SIGNALS}`,
      QueryConditions,
    );

    const evaluationsData = [
      ...sampleDefaultEvaluations,
      ...evaluationsResult.data,
    ];

    return { evaluationsData, signalsData: signalsResult.data };
  } catch (error) {
    console.error("Error fetching evaluations or signals:", error);
    return { evaluationsData: [], signalsData: [] }; // Return empty arrays in case of error
  }
};

const NewOrganization = ({
  onBack,
  onFinish,
  organization,
  evaluationForms,
}) => {
  const [current, setCurrent] = useState(0);
  const [organizationName, setOrganizationName] = useState(
    organization ? organization.name : "",
  );

  const [form] = Form.useForm();
  const [openCurrentEvaluation, setOpenCurrentEvaluation] = useState(null);
  const [sampleEvaluations, setSampleEvaluations] = useState(null);
  const [sampleSignals, setSampleSignals] = useState([]);
  const [selectedSignals, setSelectedSignals] = useState([]);
  const [openCurrentSignal, setOpenCurrentSignal] = useState(null);

  const [selectedEvaluations, setSelectedEvaluations] = useState([]);

  const next = () => {
    if (current === 0 && !validateOrganizationName(organizationName)) {
      return;
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  useEffect(() => {
    const fetchData = async () => {
      const { evaluationsData, signalsData } =
        await fetchEvaluationsAndSignals();
      setSampleEvaluations(evaluationsData);
      setSampleSignals(signalsData);
    };
    console.log("henaaa ?? ?");
    fetchData();
  }, []);

  const renderStepContent = (current) => {
    switch (current) {
      case 0:
        return (
          <Form
            form={form}
            layout="vertical"
            style={{
              width: "50%",
              margin: "0 auto",
              padding: `${elementSize.lg} 0px`,
            }}
          >
            <Form.Item
              label="Organization Name"
              rules={[
                { required: true, message: "Please input organization name!" },
              ]}
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontWeight: fontWeight.semiBold,
              }}
            >
              <Input
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
              />
            </Form.Item>
          </Form>
        );
      case 1:
        return (
          <>
            <h5>Sample Evaluations</h5>
            <List
              loading={!sampleEvaluations}
              style={{ padding: elementSize.xs }}
              dataSource={sampleEvaluations}
              renderItem={(evaluation: any, index) => (
                <List.Item
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                  onClick={() =>
                    handleCheckboxChange(
                      evaluation,
                      selectedEvaluations,
                      setSelectedEvaluations,
                    )
                  }
                  actions={[
                    <>
                      {selectedEvaluations?.some(
                        (e) => e.id === evaluation.id,
                      ) && (
                        <EyeOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEyeClick(
                              evaluation,
                              setOpenCurrentEvaluation,
                            );
                          }}
                        />
                      )}
                    </>,
                  ]}
                >
                  <div>
                    <Checkbox
                      checked={selectedEvaluations?.some(
                        (e) => e.id === evaluation.id,
                      )}
                      style={{ marginRight: "10px" }}
                    />
                    {evaluation.title}
                  </div>
                </List.Item>
              )}
            />
            {organization && (
              <>
                <h5>Existing Evaluations</h5>
                <Spin spinning={organization && !evaluationForms}>
                  <List
                    style={{ padding: elementSize.xs }}
                    dataSource={
                      evaluationForms
                        ? Object.values(evaluationForms).filter(
                            (form: EvaluationFormData) => !form.deleted,
                          )
                        : []
                    }
                    renderItem={(evaluation: EvaluationFormData, index) => (
                      <List.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          borderBottom:
                            index !==
                              Object.values(evaluationForms).filter(
                                (form: EvaluationFormData) => !form.deleted,
                              ).length -
                                1 && `1px solid ${color.gray}`,
                        }}
                      >
                        {evaluation.title}
                      </List.Item>
                    )}
                  />
                </Spin>
              </>
            )}
            {!!openCurrentEvaluation && (
              <SampleEvaluationModal
                open={!!openCurrentEvaluation}
                selectedEvaluation={openCurrentEvaluation}
                setSelectedEvalution={setOpenCurrentEvaluation}
                selectedEvaluations={selectedEvaluations}
                sampleEvaluations={sampleEvaluations}
                setSampleEvaluations={setSampleEvaluations}
                setSelectedEvaluations={setSelectedEvaluations}
              />
            )}
          </>
        );
      case 2:
        return (
          <>
            <h5>Sample Signals</h5>
            <List
              loading={!sampleSignals}
              style={{ padding: elementSize.xs }}
              dataSource={sampleSignals}
              renderItem={(signal) => (
                <List.Item
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                  onClick={() =>
                    handleCheckboxChange(
                      signal,
                      selectedSignals,
                      setSelectedSignals,
                    )
                  }
                  actions={[
                    <>
                      {selectedSignals?.some((s) => s.id === signal.id) && (
                        <EyeOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEyeClick(signal, setOpenCurrentSignal);
                          }}
                        />
                      )}
                    </>,
                  ]}
                >
                  <div>
                    <Checkbox
                      checked={selectedSignals?.some((s) => s.id === signal.id)}
                      style={{ marginRight: "10px" }}
                    />
                    {signal.title}
                  </div>
                </List.Item>
              )}
            />

            {!!openCurrentSignal && (
              <SampleSignalModal
                open={!!openCurrentSignal}
                selectedSignal={openCurrentSignal}
                setSelectedSignal={setOpenCurrentSignal}
                selectedSignals={selectedSignals}
                sampleSignals={sampleSignals}
                setSampleSignals={setSampleSignals}
                setSelectedSignals={setSelectedSignals}
              />
            )}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div
        onClick={onBack}
        style={{ marginBottom: elementSize.md, cursor: "pointer" }}
      >
        <ArrowLeftOutlined
          style={{
            marginRight: elementSize.xs,
          }}
        />
        Back
      </div>

      <Steps current={current} items={items} />
      <div style={contentStyle}>{renderStepContent(current)}</div>
      <div
        style={{ marginTop: 24, display: "flex", justifyContent: "flex-end" }}
      >
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}

        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => {
              onFinish(organizationName, selectedEvaluations, selectedSignals);
            }}
          >
            Done
          </Button>
        )}
      </div>
    </>
  );
};

export const ManageOrganizations = () => {
  const { organization, allExistingOrganizations, evaluationForms } =
    useOrganizationState();

  const [creatingOrganization, setCreatingOrganization] = useState(false);
  const [editingOrganization, setEditingOrganization] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCreateOrganization = () => {
    setCreatingOrganization(true);
  };

  const handleBack = () => {
    setCreatingOrganization(false);
    setEditingOrganization(null);
  };

  const handleFinish = async (
    organizationName,
    selectedEvaluations,
    selectedSignals,
  ) => {
    console.log("Creating organization with name:", organizationName);
    console.log("Selected evaluations:", selectedEvaluations);

    setLoading(true);
    let orgId;
    try {
      if (editingOrganization) {
        orgId = organization;
        await updateDocInFireStore(`/organization/${orgId}`, {
          name: organizationName,
        });
      } else {
        // Creating new organization
        const organizationDocRefId = await addDocumentWithId("/organization", {
          name: organizationName,
          numberOfSeats: 0,
        });
        orgId = organizationDocRefId;
      }

      const evaluationPromises = selectedEvaluations.map(async (evaluation) => {
        delete evaluation.id;

        const evaluationId = await addDocumentWithId(
          `/organization/${orgId}/${COLLECTION_DATA.EVALUATION_FORMS}`,
          evaluation,
        );

        await addDocumentWithId(
          `/organization/${orgId}/${COLLECTION_DATA.EVALUATION_FORMS}/${evaluationId}/${COLLECTION_DATA.HISTORY}`,
          evaluation,
          `${evaluation.updatedVersion}`,
        );
      });
      const signalPromises = selectedSignals.map(async (signal) => {
        delete signal.id;
        await addDocumentWithId(
          `/organization/${orgId}/${COLLECTION_DATA.SIGNALS}`,
          signal,
        );
      });

      await Promise.all([...evaluationPromises, ...signalPromises]);

      message.success(
        `Organization ${organizationName} has been ${editingOrganization ? "updated" : "created"} successfully`,
      );
    } catch (error) {
      console.error("Error creating/updating organization:", error);
      message.error(
        `Failed to ${editingOrganization ? "update" : "create"} organization ${organizationName}`,
      );
    } finally {
      setLoading(false);
      setCreatingOrganization(false);
    }
  };

  const handleEditOrganization = (organization) => {
    const currentOrganization = allExistingOrganizations.find(
      (existingOrg) => existingOrg.id === organization,
    );
    setEditingOrganization(currentOrganization);
    setCreatingOrganization(true);
  };

  return (
    <Spin spinning={loading}>
      {!creatingOrganization ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 12px",
          }}
        >
          <SpaceBetweenDiv>
            <h2>My Organizations</h2>
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    handleEditOrganization(organization);
                  }}
                >
                  <EditOutlined />
                  <DeskTopOnly>Edit Organization</DeskTopOnly>
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={handleCreateOrganization}>
                  + <DeskTopOnly>New Organization</DeskTopOnly>
                </Button>
              </Col>
            </Row>
          </SpaceBetweenDiv>
        </div>
      ) : (
        <NewOrganization
          onBack={handleBack}
          onFinish={handleFinish}
          organization={editingOrganization}
          evaluationForms={evaluationForms}
        />
      )}
    </Spin>
  );
};
