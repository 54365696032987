import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, message, Row, Space, Spin } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import ClipPlayer, { ClipPlayerRef } from "../ClipPlayer/ClipPlayer";
import { Container } from "../ClipPlayer/ClipPlayer.styles";
import { decryptId } from "src/utils/utils";
import { OpenInNew } from "@mui/icons-material";
import { useModal } from "src/state/UseModal";
import { ClipActions } from "./PlaylistDetails.styles";
import {
  CollaboratorAvatar,
  PoweredByPublicFooter,
} from "src/components/Global";
import { elementSize } from "src/styles/variables";
import { getClipInfoFromPlaylist } from "../Playlist.utils";
import { fetchFileUrl } from "src/components/Dashboard/Dashboard.utils";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { getRecordFromFireStore, updateDocInFireStore } from "src/firebaseAuth";
import { playlistConverter } from "src/utils/converter";
import { UtterancesList } from "src/components/Dashboard/ConversationModal/ConversationModalBody/UtterancesList";

const ClipDetailsModalContent = () => {
  const { modalState } = useModal();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const isShareableClip = !!queryParams.get(QUERY_PARAMS.SHAREABLE_CLIP); // Check if 'sharableClip=true'
  const pathSegments = location.pathname.split("/").filter(Boolean);

  const organizationId = pathSegments[1] && decryptId(pathSegments[1]);
  const [clipOrganizationId, setClipOrganizationId] = useState(organizationId);
  const playlistId = pathSegments[2] && decryptId(pathSegments[2]);

  const clipId = modalState.id;
  const clipPlayerRef = useRef<ClipPlayerRef>(null);
  const { organization, collaborators, playlists } = useOrganizationState();

  const [selectedClip, setSelectedClip] = useState(null);
  const [signalPlaylistUser, setSignalPlaylistUser] = useState(null);
  const [currentUtteranceIndex, setCurrentUtteranceIndex] = useState<
    number | null
  >(null);
  const [startTime, setStartTime] = useState<number>(selectedClip?.start);
  const [utterances, setUtterances] = useState([]);
  const [showHighlights, setShowHighlights] = useState(
    selectedClip?.timeline?.length > 0,
  );

  const fetchPlaylistFromFirestore = async () => {
    const { foundClip: clipFound, playlistUser } =
      await getClipInfoFromPlaylist(playlistId, clipId, organizationId);
    const url = await fetchFileUrl(clipFound.conversationId);
    setSelectedClip({ ...clipFound, url });
    setSignalPlaylistUser(playlistUser);
  };

  const fetchUtterances = async () => {
    const transcript = await getRecordFromFireStore(
      `organization/${organizationId}/${COLLECTION_DATA.CONVERSATIONS}/${selectedClip.conversationId}`,
    );
    let utterances = transcript?.transcript_data?.utterances;
    if (selectedClip.timeline && selectedClip.timeline.length > 0) {
      utterances = transcript.transcript_data.utterances.filter((utterance) => {
        return selectedClip.timeline.some(
          (timeRange) =>
            utterance.start >= timeRange.start &&
            utterance.end <= timeRange.end,
        );
      });
    } else {
      utterances = transcript.transcript_data.utterances.filter(
        (utterance) =>
          utterance.start >= selectedClip.start &&
          utterance.end <= selectedClip.end,
      );
    }
    setUtterances(utterances);
  };

  const fetchSharableClip = async () => {
    const clipData = await getRecordFromFireStore(
      `${COLLECTION_DATA.CLIPS}/${clipId}`,
    );
    setClipOrganizationId(clipData.organizationId);
    const url = await fetchFileUrl(clipData.conversationId);
    setSelectedClip({ ...clipData, url });
    setUtterances(clipData.utterances);
  };

  useEffect(() => {
    const fetchClipDetails = async () => {
      if (clipId) {
        const playlist = playlists?.data?.find(
          (playlist) => playlist.id === playlistId,
        );
        const clip =
          playlist?.clips?.find((clip) => clip.id === clipId) || null;
        if (!clip) {
          fetchPlaylistFromFirestore();
        } else {
          setSignalPlaylistUser(playlist?.createdBy);
          const url = await fetchFileUrl(clip.conversationId);
          setSelectedClip({ ...clip, url });
        }
      }
    };
    if (isShareableClip) {
      fetchSharableClip();
    } else {
      fetchClipDetails();
    }
  }, [clipId]);

  useEffect(() => {
    if (!selectedClip || isShareableClip) return;

    if (
      !selectedClip.mimeType.includes("text") ||
      selectedClip.timeline?.length > 0
    ) {
      fetchUtterances();
    } else {
      setUtterances(selectedClip.utterances);
    }
  }, [selectedClip]);

  useEffect(() => {
    return () => {
      if (clipPlayerRef.current) {
        clipPlayerRef.current.pause();
        clipPlayerRef.current = null;
      }
    };
  }, []);

  const handleDeleteClipFromPlaylistSignal = async (timelineIndex) => {
    // Remove the specific timeline item from the clip
    const updatedTimeline = selectedClip.timeline.filter(
      (_, index) => index !== timelineIndex,
    );
    const updatedClip = {
      ...selectedClip,
      updatedAt: new Date().toISOString(),
      timeline: updatedTimeline,
    };
    // Update the local state
    setSelectedClip(updatedClip);
    const playlistPath = `/organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`;

    try {
      // Get the existing playlist from Firestore
      const existingPlaylist = await getRecordFromFireStore(
        playlistPath,
        playlistConverter,
      );
      // Find the index of the clip to update in the playlist
      const updatedClips = existingPlaylist.clips.map((clip) =>
        clip?.conversationId === selectedClip?.conversationId
          ? updatedClip
          : clip,
      );

      // Create the updated playlist data
      const updatedPlaylist = {
        ...existingPlaylist,
        clips: updatedClips,
      };

      // Update the Firestore document
      await updateDocInFireStore(
        playlistPath,
        updatedPlaylist,
        playlistConverter,
      );
      message.success("Clip removed succesfully");
      // If the clip is removed, close the modal
      console.log("Timeline item removed and Firestore updated successfully");
    } catch (error) {
      message.error("Clip failed to remove");
      console.log("Error updating Firestore:", error);
    }
  };

  const handleUtteranceClick = (index) => {
    if (selectedClip.mimeType.includes("text")) return;
    setCurrentUtteranceIndex(index);
    const utterance = utterances[index];
    setStartTime(utterance.start);
  };

  const updatedUtteranceIndex = (time) => {
    const utteranceIndex = utterances?.findIndex(
      (utterance) => utterance.start <= time && utterance.end >= time,
    );
    setCurrentUtteranceIndex(utteranceIndex);
  };

  return (
    <Spin spinning={!selectedClip}>
      {selectedClip && (
        <ClipActions style={{ flexDirection: "column", gap: elementSize.md }}>
          {selectedClip.timeline && selectedClip.timeline.length > 0 ? (
            <>
              <Row>
                {(selectedClip.mimeType.includes("audio") ||
                  selectedClip.mimeType.includes("video")) && (
                  <Col xl={!showHighlights ? 17 : 24} xs={24}>
                    <ClipPlayer
                      url={selectedClip.url}
                      timeline={selectedClip.timeline}
                      mimeType={selectedClip.mimeType}
                      ref={clipPlayerRef}
                      handleDeleteClipFromPlaylistSignal={
                        handleDeleteClipFromPlaylistSignal
                      }
                      signalPlaylistUser={signalPlaylistUser}
                      showHighlights={showHighlights}
                      setShowHighlights={setShowHighlights}
                      updatedUtteranceIndex={updatedUtteranceIndex}
                      enableHighlight={
                        selectedClip.timeline &&
                        selectedClip?.timeline.length > 1
                      }
                      start={startTime}
                    />
                  </Col>
                )}
                {!showHighlights && (
                  <Col
                    xl={selectedClip.mimeType.includes("text") ? 24 : 7}
                    xs={24}
                  >
                    <Container>
                      <UtterancesList
                        utterances={utterances}
                        currentUtteranceIndex={currentUtteranceIndex}
                        handleUtteranceClick={handleUtteranceClick}
                        action={false}
                      />
                    </Container>
                  </Col>
                )}
              </Row>
            </>
          ) : (
            <>
              <Row gutter={[8, 32]}>
                {(selectedClip.mimeType.includes("audio") ||
                  selectedClip.mimeType.includes("video")) && (
                  <Col xl={15} xs={24}>
                    <ClipPlayer
                      url={selectedClip.url}
                      timeline={[
                        { start: selectedClip.start, end: selectedClip.end },
                      ]}
                      mimeType={selectedClip.mimeType}
                      updatedUtteranceIndex={updatedUtteranceIndex}
                      start={startTime}
                    />
                  </Col>
                )}

                <Col
                  xl={selectedClip.mimeType.includes("text") ? 24 : 9}
                  xs={24}
                >
                  <Container>
                    <UtterancesList
                      utterances={utterances}
                      currentUtteranceIndex={currentUtteranceIndex}
                      {...(selectedClip.mimeType.includes("text")
                        ? {}
                        : { handleUtteranceClick })}
                      action={false}
                    />
                  </Container>
                </Col>
              </Row>

              <Space>
                Clipped By :{" "}
                <CollaboratorAvatar
                  collaborator={
                    collaborators?.find(
                      (user) => user?.email === selectedClip.user,
                    ) || { email: selectedClip.user }
                  }
                />
                <>{selectedClip.user}</>
              </Space>
            </>
          )}
          {selectedClip?.summary && (
            <Space>
              <strong>Summary:</strong> {selectedClip.summary}
            </Space>
          )}

          {clipOrganizationId === organization ? (
            <Button
              onClick={() => {
                navigate(
                  `?conversation=${encodeURIComponent(selectedClip.conversationId)}`,
                );
              }}
            >
              <OpenInNew style={{ fontSize: "16px", cursor: "pointer" }} /> View
              full conversation
            </Button>
          ) : (
            <PoweredByPublicFooter />
          )}
        </ClipActions>
      )}
    </Spin>
  );
};

export default ClipDetailsModalContent;
