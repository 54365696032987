import React from "react";
import { Tag } from "antd";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { capitalizeFirstLetter, getColorForValue } from "src/utils/utils";
import { spacing } from "src/styles/variables";

export const DraggableBoard = ({ data, onDragEnd, renderItem }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-stages" direction="horizontal" type="Stage">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ display: "flex", overflow: "auto" }}
          >
            {Object.entries(data).map(([stage, items]: any, index, array) => {
              // Check if the stage is "Unassigned Stage"
              const isUnassignedStage = stage === "Unassigned Stage";

              return isUnassignedStage ? (
                <div
                  key={stage}
                  style={{
                    minWidth: 320,
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 8,
                    marginRight: index === array.length - 1 ? 0 : 25,
                  }}
                >
                  <div style={{ margin: `${spacing.md} ${spacing.xs}` }}>
                    <Tag
                      color={getColorForValue(stage)}
                      style={{ fontSize: 16, padding: "4px 8px" }}
                    >
                      {capitalizeFirstLetter(stage)} ({items.length})
                    </Tag>
                  </div>

                  <Droppable droppableId={stage} type="customer">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {items.length === 0 && (
                          <div
                            style={{
                              padding: spacing.md,
                              textAlign: "center",
                              color: "#999",
                              borderRadius: spacing.sm,
                              backgroundColor: "#fff",
                            }}
                          >
                            No customers in this stage
                          </div>
                        )}
                        {items.map((item, index) => (
                          <Draggable
                            key={`${item?.id}-${index}`}
                            draggableId={item?.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom: 16,
                                }}
                              >
                                {renderItem(item)}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ) : (
                <Draggable key={stage} draggableId={stage} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        minWidth: 320,
                        padding: "10px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: 8,
                        marginRight: index === array.length - 1 ? 0 : 25,
                      }}
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={{
                          margin: `${spacing.md} ${spacing.xs}`,
                          cursor: "grab",
                        }}
                      >
                        <Tag
                          color={getColorForValue(stage)}
                          style={{ fontSize: 16, padding: "4px 8px" }}
                        >
                          {capitalizeFirstLetter(stage)} ({items.length})
                        </Tag>
                      </div>

                      <Droppable droppableId={stage} type="customer">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {items.length === 0 && (
                              <div
                                style={{
                                  padding: spacing.md,
                                  textAlign: "center",
                                  color: "#999",
                                  borderRadius: spacing.sm,
                                  backgroundColor: "#fff",
                                }}
                              >
                                No customers in this stage
                              </div>
                            )}
                            {items.map((item, index) => (
                              <Draggable
                                key={`${item?.id}-${index}`}
                                draggableId={item?.id}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      marginBottom: 16,
                                    }}
                                  >
                                    {renderItem(item)}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
