import { useUserState } from "src/state/UserState";

import {
  Backdrop,
  ErrorMsg,
  ScrollableDiv,
  ErrorBack,
  NavigateBack,
  DivLink,
  AddRemoveTags,
} from "src/components";
import { SectionContainer } from "../LandingPage/LandingPage.styles";
import {
  Collapse,
  Dropdown,
  Form,
  Input,
  notification,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import {
  CardLayoutContainer,
  ContentSection,
  EvaluationSubTitle,
  FormContainer,
  PhrasesPanel,
  SidebarSection,
  TitleUnderline,
} from "./Evaluation.styles";
import { color, elementSize, spacing } from "src/styles/variables";
import {
  FloatingLabelInput,
  FloatingLabelTextArea,
  FloatingSelectWrapper,
} from "../FloatingInputText/FloatingInputText";
import {
  EvaluationInformation,
  evaluationInformationDetails,
  signalInformationDetails,
} from "./Information/EvaluationInformation";
import { useEffect, useState } from "react";
import { EvaluationFormType, SignalType } from "src/utils/types";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import {
  CommonMenu,
  handleChangeInEvaluation,
  removeError,
  validateEvaluationFields,
} from "./Evaluation.utils";
import { addDocumentWithId, updateDocInFireStore } from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import {
  ACCESS_TYPE,
  COLLECTION_DATA,
  NotificationType,
  PLAYLIST_ACCESS,
  PLAYLIST_TYPE,
} from "src/utils/enums";
import { useNavigate, useParams } from "react-router-dom";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import SettingsSection from "./EvaluationSettings/EvaluationSettings";

import {
  InfoCircleOutlined,
  MoreOutlined,
  ExperimentOutlined,
  SlackOutlined,
} from "@ant-design/icons";
import { DeskTopOnly, MobileOnly } from "src/styles/stylingComponents";
import EvaluationFooterButtons from "./EvaluationFooterButtons/EvaluationFooterButtons";
import EvaluationCategories from "./EvaluationCategories/EvaluationCategories";
import { EvaluationPresetCategoriesModal } from "./EvaluationCategories/EvaluationPresetCategoriesModal";
import { capitalizeFirstLetter } from "src/utils/utils";
import { FORM_TYPE } from "../Profile/Profile.enums";
import {
  generateAccessOptions,
  getVisibilityIcon,
  handleCreatePlaylist,
  handleDeletePlaylist,
} from "../Playlist/Playlist.utils";
import { validateSignalFields } from "./Signals";
import EditableList from "./EditableList";

const { Option } = Select;

const collectionConfigs = {
  [FORM_TYPE.EVALUATION]: {
    route: "/evaluation",
    collectionName: COLLECTION_DATA.EVALUATION_FORMS,
  },
  [FORM_TYPE.SIGNALS]: {
    route: "/signals",
    collectionName: COLLECTION_DATA.SIGNALS,
  },
};

interface CommonFormProps {
  type: FORM_TYPE;
  existingData: any;
  selectedItem: EvaluationFormType | SignalType;
  setSelectedItem: any;
  currentSelectedItem: EvaluationFormType | SignalType;
  setCurrentSelectedItem: any;
  setExistingData?: (data: any) => void;
  showTestCard?: boolean;
  setShowTestCard?: any;
  slackChannels?: any;
}

export const CommonForm: React.FC<CommonFormProps> = ({
  type,
  existingData,
  selectedItem,
  setSelectedItem,
  currentSelectedItem,
  setCurrentSelectedItem,
  setExistingData,
  showTestCard,
  setShowTestCard,
  slackChannels,
}) => {
  const { organization, organizationSettings, setPlaylists } =
    useOrganizationState();
  const [form] = Form.useForm();

  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const isEditMode = id != null; // true if 'id' is present in the URL
  const [disableEditing, setDisableEditing] = useState(isEditMode);
  const [mobileSidebarVisible, setMobileSidebarVisible] = useState(false);
  const [settingsKey, setSettingsKey] = useState(0);

  const [errors, setErrors] = useState({}); // Managing errors for inputs
  const [loading, setLoading] = useState(false);
  const [openEvaluationCatgoriesPreset, setOpenEvaluationCatgoriesPreset] =
    useState(false);
  const [signalPlayListvisibility, setSignalPlayListvisibility] = useState(
    PLAYLIST_ACCESS.PRIVATE,
  ); // Set default value here
  const { user } = useUserState();
  const navigate = useNavigate();
  const hasAccess =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;
  const [disableInputs, setdisableInputs] = useState(
    !hasAccess || disableEditing,
  );
  let analysisStructure;

  const formProps =
    type === FORM_TYPE.SIGNALS
      ? {
          form: form,
        }
      : {};
  analysisStructure =
    type === FORM_TYPE.EVALUATION &&
    (currentSelectedItem as EvaluationFormType)?.analysisStructures;

  useEffect(() => {
    if (isEditMode) {
      if (!existingData) {
        setLoading(true);
      } else {
        if (existingData?.[id]) {
          const item = existingData[id];
          setSelectedItem(item);
          setCurrentSelectedItem(cloneDeep(item));
          type === FORM_TYPE.SIGNALS && form.setFieldsValue(item); // Update form values
        } else {
          setCurrentSelectedItem(null);
        }
        setLoading(false);
      }
    }
  }, [id, existingData, isEditMode, form]);

  const handleCancelEdit = () => {
    setCurrentSelectedItem(cloneDeep(selectedItem));
    setMobileSidebarVisible(false);
    toggleEditMode();
    switch (type) {
      case FORM_TYPE.EVALUATION:
        setErrors({});
        setSettingsKey((prevKey) => prevKey + 1);
        break;
      case FORM_TYPE.SIGNALS:
        form.setFieldsValue(cloneDeep(selectedItem));
        break;
      default:
        // Default case if needed
        break;
    }
  };

  const toggleEditMode = () => {
    const editValue = !disableEditing;
    setdisableInputs(!hasAccess || editValue);
    setDisableEditing(editValue);
  };
  const toggleMobileSidebar = () => {
    setMobileSidebarVisible(!mobileSidebarVisible);
  };
  const setAnalysisStructures = (newAnalysisStructure) => {
    setCurrentSelectedItem((prevEvaluation) => ({
      ...prevEvaluation, // Spread operator to copy properties of the object
      analysisStructures: newAnalysisStructure, // Update analysisStructure with the new value
    }));
  };

  const handleSubmit = async (toDelete = false) => {
    const updateEvaluationSignalInDB = async (itemId, updatedVersion?) => {
      const newItem = {
        ...currentSelectedItem,
        timestamp: new Date().toISOString(),
      };
      if (type === FORM_TYPE.EVALUATION) {
        (newItem as EvaluationFormType).version = updatedVersion;
      }
      if (itemId) {
        await updateDocInFireStore(
          `/organization/${organization}/${collectionConfigs[type].collectionName}/${itemId}`,
          newItem,
        );
      } else {
        if (type === FORM_TYPE.SIGNALS) {
          let PlaylistId = await handleCreatePlaylist(
            newItem.title,
            null,
            organization,
            user.email,
            setPlaylists,
            newItem.description,
            PLAYLIST_TYPE.SIGNAL,
            signalPlayListvisibility,
          );
          (newItem as SignalType).playlist_id = PlaylistId;
        }

        itemId = await addDocumentWithId(
          `/organization/${organization}/${collectionConfigs[type].collectionName}`,
          newItem,
        );
      }
      if (type === FORM_TYPE.EVALUATION) {
        setExistingData((prevEvaluationForms) => ({
          ...prevEvaluationForms,
          [itemId]: { ...newItem, id: itemId },
        }));

        await addDocumentWithId(
          `/organization/${organization}/${COLLECTION_DATA.EVALUATION_FORMS}/${itemId}/${COLLECTION_DATA.HISTORY}`,
          newItem,
          `${updatedVersion}`,
        );
      }
      return itemId;
    };

    if (
      type === FORM_TYPE.EVALUATION &&
      !validateEvaluationFields(
        currentSelectedItem,
        analysisStructure,
        setErrors,
      )
    ) {
      return;
    }

    if (
      type === FORM_TYPE.SIGNALS &&
      !validateSignalFields(currentSelectedItem, setErrors)
    ) {
      return;
    }

    setLoading(true);
    try {
      if (isEditMode) {
        const itemId = currentSelectedItem.id;
        if (toDelete) {
          currentSelectedItem.deleted = true;
          if (existingData && existingData[itemId]) {
            existingData[itemId].deleted = true;
            if (type === FORM_TYPE.SIGNALS) {
              await handleDeletePlaylist(
                existingData[itemId].playlist_id,
                organization,
                setPlaylists,
              );
            }
          }
        }

        let updatedVersion;
        if (type === FORM_TYPE.EVALUATION) {
          updatedVersion =
            ((currentSelectedItem as EvaluationFormType).version || 0) + 1;
        }
        await updateEvaluationSignalInDB(itemId, updatedVersion);

        // const updatedVersion = (currentSelectedItem.version || 0) + 1;

        openNotificationWithIcon(
          "success",
          `${capitalizeFirstLetter(type)} ${toDelete ? "Deleted" : "Updated"} Successfully`,
          "",
        );
      } else {
        let version = type === FORM_TYPE.EVALUATION ? 1 : null;
        const itemId = await updateEvaluationSignalInDB(null, version);
        openNotificationWithIcon(
          "success",
          `${capitalizeFirstLetter(type)} Created Successfully`,
          "",
        );
        navigate(`${collectionConfigs[type].route}/${itemId}`);
      }
      toggleEditMode();
    } catch (err) {
      console.log(err);
      openNotificationWithIcon(
        "error",
        `${capitalizeFirstLetter(type)} Failed to save`,
        err,
      );
    }
    setLoading(false);
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    msgTitle: string,
    msgContent: string,
  ) => {
    api[type]({
      message: msgTitle,
      description: msgContent,
    });
  };

  const handleDuplicate = () => {
    const newItem = cloneDeep(currentSelectedItem);
    newItem.id = null; // Reset the ID to null for a new evaluation
    setSelectedItem(newItem);
    setCurrentSelectedItem(newItem);
    toggleEditMode();
    navigate(`${collectionConfigs[type].route}`);
  };

  const handleDelete = async () => {
    await handleSubmit(true);
    navigate("/profile");
  };

  const menu = (
    <CommonMenu
      onEdit={toggleEditMode}
      onDuplicate={handleDuplicate}
      onDelete={handleDelete}
      ComponentName={capitalizeFirstLetter(type)}
    />
  );

  if (type === FORM_TYPE.EVALUATION && !analysisStructure) {
    return (
      <Spin spinning={loading}>
        <SectionContainer>
          <ErrorBack
            title={capitalizeFirstLetter(type)}
            routingName={"profile"}
            routingUrl={"/profile"}
          />
        </SectionContainer>
      </Spin>
    );
  }

  if (type === FORM_TYPE.SIGNALS && !currentSelectedItem) {
    return (
      <Spin spinning={loading}>
        <SectionContainer>
          <ErrorBack
            title={capitalizeFirstLetter(type)}
            routingName={"profile"}
            routingUrl={"/profile"}
          />
        </SectionContainer>
      </Spin>
    );
  }

  const { generalAccessOptions } = generateAccessOptions(
    organizationSettings?.name,
  );

  const renderFormContent = (type) => {
    switch (type) {
      case FORM_TYPE.EVALUATION:
        return (
          <>
            <Form.Item
              name="evaluation-title"
              style={{ marginTop: spacing.lg, marginBottom: "0px" }}
            >
              <FloatingLabelInput
                id="title"
                label={`Title`}
                style={{
                  marginBottom: spacing.md,
                }}
                inputValue={currentSelectedItem?.title}
                required
                disabled={isEditMode || !hasAccess}
                showLabelOnDisable={true}
                error={errors["evaluation-title"]}
                onChangeInput={(e) =>
                  handleChangeInEvaluation(
                    "title",
                    e.target.value,
                    setCurrentSelectedItem,
                    "evaluation-title",
                    errors,
                    setErrors,
                  )
                }
              />
            </Form.Item>
            <Form.Item name="evaluation-description">
              <FloatingLabelTextArea
                style={{ marginBottom: spacing.md }}
                id="knowledge"
                label="Knowledge"
                inputValue={currentSelectedItem?.description}
                onChangeInput={(e) =>
                  handleChangeInEvaluation(
                    "description",
                    e.target.value,
                    setCurrentSelectedItem,
                    "evaluation-description",
                    errors,
                    setErrors,
                  )
                }
                required
                autoSize={{ minRows: 2, maxRows: 6 }}
                disabled={disableInputs}
                showLabelOnDisable={true}
                error={errors[`evaluation-description`]}
              />
            </Form.Item>
            <EvaluationSubTitle>Settings</EvaluationSubTitle>
            {!loading && (
              <SettingsSection
                key={settingsKey}
                currentSelectedEvaluation={currentSelectedItem}
                setCurrentSelectedEvaluation={setCurrentSelectedItem}
                handleChange={handleChangeInEvaluation}
                disabled={disableInputs}
              />
            )}
            <FloatingSelectWrapper
              label="Teams"
              id="teams-select"
              required={true}
              mode="multiple"
              allowClear
              style={{
                width: "100%",
                padding: `${spacing.md} 0px`,
                marginBottom: spacing.md,
              }}
              placeholder="Please select teams associated with this evaluation"
              onChange={(e) =>
                handleChangeInEvaluation(
                  "teams",
                  e,
                  setCurrentSelectedItem,
                  "evaluation-teams",
                  errors,
                  setErrors,
                )
              }
              options={organizationSettings?.teams.map((team) => ({
                label: team,
                value: team,
              }))}
              defaultValue={(currentSelectedItem as EvaluationFormType)?.teams}
              disabled={isEditMode || !hasAccess}
            />
            <SpaceBetweenDiv>
              <EvaluationSubTitle>Categories</EvaluationSubTitle>
              {!disableInputs && (
                <DivLink
                  style={{
                    color: color.orange,
                    textDecoration: "underline",
                  }}
                  onClick={() => setOpenEvaluationCatgoriesPreset(true)}
                >
                  View Category Presets
                </DivLink>
              )}
            </SpaceBetweenDiv>
            <EvaluationCategories
              analysisStructure={analysisStructure}
              setAnalysisStructures={setAnalysisStructures}
              setCurrentSelectedEvaluation={setCurrentSelectedItem}
              disableInputs={disableInputs}
              errors={errors}
              setErrors={setErrors}
            />
          </>
        );

      case FORM_TYPE.SIGNALS:
        const slackConnectUrl = `${window.location.origin}/profile?tab=Integrations#:~:text=Organizational%20Integrations-,Slack,-Alerts`;

        return (
          <>
            <Form.Item
              name="title"
              label="Title"
              style={{ marginTop: elementSize.sm }}
              required
            >
              <Input
                id="title"
                value={currentSelectedItem?.title}
                onChange={(e) => {
                  removeError("signal-title", errors, setErrors);
                  setCurrentSelectedItem({
                    ...currentSelectedItem,
                    title: e.target.value,
                  });
                }}
                style={{ borderColor: errors["signal-title"] && color.orange }}
                placeholder="Title"
                required
                disabled={isEditMode}
              />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea
                id="description"
                value={currentSelectedItem?.description}
                onChange={(e) =>
                  setCurrentSelectedItem({
                    ...currentSelectedItem,
                    description: e.target.value,
                  })
                }
                placeholder="Add a description of what signal you're trying to track here!"
                required
                autoSize={{ minRows: 2, maxRows: 6 }}
                disabled={disableInputs}
              />
            </Form.Item>
            {organizationSettings.slack && (
              <Form.Item
                label={
                  <>
                    <SlackOutlined style={{ marginRight: 8 }} />
                    Slack Channel
                  </>
                }
              >
                <Select
                  mode="multiple"
                  allowClear={true}
                  disabled={disableInputs}
                  value={
                    (currentSelectedItem as SignalType)?.slackChannelIds || []
                  }
                  onChange={(value) =>
                    setCurrentSelectedItem({
                      ...currentSelectedItem,
                      slackChannelIds: value.length ? value : [],
                    })
                  }
                >
                  {slackChannels.map((channel) => (
                    <Option key={channel.id} value={channel.id}>
                      {channel.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item label="Keywords">
              <AddRemoveTags
                tagType={"keyword"}
                tags={(currentSelectedItem as SignalType)?.keywords || []}
                onChange={(newItems) =>
                  setCurrentSelectedItem({
                    ...currentSelectedItem,
                    keywords: newItems,
                  })
                }
                disabled={disableInputs}
                style={{ justifyContent: "flex-end" }}
              />
            </Form.Item>
            <Form.Item label="Tags">
              <AddRemoveTags
                tagType={"tag"}
                tags={(currentSelectedItem as SignalType)?.tags || []}
                onChange={(newItems) =>
                  setCurrentSelectedItem({
                    ...currentSelectedItem,
                    tags: newItems,
                  })
                }
                disabled={disableInputs}
                style={{ justifyContent: "flex-end" }}
              />
            </Form.Item>
            <Form.Item>
              <Collapse defaultActiveKey={["1"]} ghost>
                <PhrasesPanel header="Phrases" key="1">
                  <EditableList
                    items={(currentSelectedItem as SignalType)?.phrases || []}
                    setItems={(newItems) =>
                      setCurrentSelectedItem({
                        ...currentSelectedItem,
                        phrases: newItems,
                      })
                    }
                    placeholder="Phrase"
                    disabled={disableInputs}
                  />
                </PhrasesPanel>
              </Collapse>
            </Form.Item>
            {!organizationSettings.slack && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: elementSize.lg,
                }}
              >
                <InfoCircleOutlined
                  style={{ marginRight: 8, color: "orange" }}
                />
                <Typography.Text>
                  You are not connected to Slack, we won't be able to notify
                  you. You can connect to Slack from{" "}
                  <a href={slackConnectUrl} style={{ fontWeight: "bold" }}>
                    this URL
                  </a>
                  .
                </Typography.Text>
              </div>
            )}

            {!isEditMode && (
              <>
                <Space align="center" style={{ marginBottom: elementSize.md }}>
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    Playlist Settings
                  </Typography.Title>
                  <Tooltip title="You can later adjust these settings in playlist">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
                <Form.Item name="visibility" label="General Access" required>
                  <Select
                    defaultValue={signalPlayListvisibility} // Bind to component state
                    onChange={(value) => setSignalPlayListvisibility(value)}
                    style={{ width: "100%" }}
                  >
                    {generalAccessOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {getVisibilityIcon(option.icon, {
                          marginRight: elementSize.xs,
                        })}{" "}
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </>
        );

      // Add more cases as needed for other form types
      default:
        return null;
    }
  };

  return (
    <FormContainer>
      {contextHolder}
      <Spin spinning={loading}>
        <Backdrop show={mobileSidebarVisible} onClick={toggleMobileSidebar} />
        {/* This will also close the sidebar when the overlay is clicked */}

        <SectionContainer>
          <CardLayoutContainer sidebar={!disableEditing}>
            <ContentSection mobileShow={mobileSidebarVisible}>
              <Form
                style={{
                  marginLeft: spacing.md,
                  width: "95%",
                }}
                {...formProps}
              >
                <Typography.Title level={4}>
                  <SpaceBetweenDiv>
                    <div>
                      <NavigateBack url={"/profile?tab=Evaluation"} />
                      {isEditMode ? !disableInputs && "Edit" : "Add"}{" "}
                      {capitalizeFirstLetter(type)}
                      {" Form"}
                    </div>
                    {disableEditing && (
                      <Dropdown overlay={menu} trigger={["click"]}>
                        <MoreOutlined style={{ cursor: "pointer" }} />
                      </Dropdown>
                    )}
                    {!disableEditing && (
                      <SpaceBetweenDiv>
                        {type === FORM_TYPE.EVALUATION && (
                          <Tooltip title="Test Evaluation Form">
                            <ExperimentOutlined
                              onClick={() => setShowTestCard((prev) => !prev)}
                              style={{
                                cursor: "pointer",
                                color: showTestCard ? color.orange : "inherit",
                              }}
                            />
                          </Tooltip>
                        )}
                        <MobileOnly>
                          <InfoCircleOutlined
                            style={{ cursor: "pointer" }}
                            onClick={toggleMobileSidebar}
                          />
                        </MobileOnly>
                      </SpaceBetweenDiv>
                    )}
                  </SpaceBetweenDiv>
                </Typography.Title>

                <TitleUnderline />
                <ScrollableDiv>{renderFormContent(type)}</ScrollableDiv>

                {Object.keys(errors)?.length > 0 && (
                  <ErrorMsg>* Required fields are missing</ErrorMsg>
                )}
                <MobileOnly>
                  <EvaluationFooterButtons
                    isEditMode={isEditMode}
                    handleSubmit={handleSubmit}
                    disabled={disableInputs}
                    handleCancelEdit={handleCancelEdit}
                    hasChanges={!isEqual(selectedItem, currentSelectedItem)}
                    toggleEditMode={toggleEditMode}
                  />
                </MobileOnly>
              </Form>
            </ContentSection>
            <SidebarSection
              show={!disableEditing}
              mobileShow={mobileSidebarVisible}
            >
              <EvaluationInformation
                toggleMobileSidebar={toggleMobileSidebar}
                information={
                  type === FORM_TYPE.SIGNALS
                    ? signalInformationDetails
                    : evaluationInformationDetails
                }
              />
              <DeskTopOnly>
                <EvaluationFooterButtons
                  isEditMode={isEditMode}
                  handleSubmit={handleSubmit}
                  disabled={disableInputs}
                  handleCancelEdit={handleCancelEdit}
                  hasChanges={!isEqual(selectedItem, currentSelectedItem)}
                  toggleEditMode={toggleEditMode}
                />
              </DeskTopOnly>
            </SidebarSection>
            {openEvaluationCatgoriesPreset && (
              <EvaluationPresetCategoriesModal
                openModal={openEvaluationCatgoriesPreset}
                setOpenModal={setOpenEvaluationCatgoriesPreset}
                analysisStructure={analysisStructure}
                setAnalysisStructures={setAnalysisStructures}
              />
            )}
          </CardLayoutContainer>
        </SectionContainer>
      </Spin>
    </FormContainer>
  );

  //   return <StandardPage content={evaluationContent} />;
};
