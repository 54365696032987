import React from "react";
import { Modal, Form, Input, Collapse } from "antd";

import { elementSize } from "src/styles/variables";
import { AddRemoveTags } from "../Global";
import EditableList from "../Evaluation/EditableList";

const { Panel } = Collapse;

export const SampleSignalModal = ({
  open,
  selectedSignal,
  setSelectedSignal,
  selectedSignals,
  setSelectedSignals,
  sampleSignals,
  setSampleSignals,
}) => {
  const [form] = Form.useForm();
  const handleSaveChanges = () => {
    if (selectedSignal) {
      // Update selectedSignals
      const updatedSelectedSignals = selectedSignals.map((signal) =>
        signal.id === selectedSignal.id ? selectedSignal : signal,
      );

      // Update sampleSignals
      const updatedSampleSignals = sampleSignals.map((signal) =>
        signal.id === selectedSignal.id ? selectedSignal : signal,
      );

      setSelectedSignals(updatedSelectedSignals);

      setSampleSignals(updatedSampleSignals); // Update the sample signals state
    }

    // Close the modal
    setSelectedSignal(null);
  };

  return (
    <Modal
      visible={open}
      width="90%"
      onCancel={() => setSelectedSignal(null)}
      onOk={handleSaveChanges} // Call the handleSaveChanges function when saving
      title="Preview Signal"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          title: selectedSignal?.title || "",
          description: selectedSignal?.description || "",
          keywords: selectedSignal?.keywords || [],
          tags: selectedSignal?.tags || [],
          phrases: selectedSignal?.phrases || [],
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          style={{ marginTop: elementSize.sm }}
          required
        >
          <Input
            id="title"
            value={selectedSignal?.title}
            onChange={(e) =>
              setSelectedSignal({ ...selectedSignal, title: e.target.value })
            }
            placeholder="Title"
            required
          />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input.TextArea
            id="description"
            value={selectedSignal?.description}
            onChange={(e) =>
              setSelectedSignal({
                ...selectedSignal,
                description: e.target.value,
              })
            }
            placeholder="Add a description of what signal you're trying to track!"
            required
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>

        <Form.Item label="Keywords">
          <AddRemoveTags
            tagType="keyword"
            tags={selectedSignal?.keywords || []}
            onChange={(newItems) =>
              setSelectedSignal({ ...selectedSignal, keywords: newItems })
            }
            style={{ justifyContent: "flex-end" }}
          />
        </Form.Item>

        <Form.Item label="Tags">
          <AddRemoveTags
            tagType="tag"
            tags={selectedSignal?.tags || []}
            onChange={(newItems) =>
              setSelectedSignal({ ...selectedSignal, tags: newItems })
            }
            style={{ justifyContent: "flex-end" }}
          />
        </Form.Item>

        <Form.Item>
          <Collapse defaultActiveKey={["1"]} ghost>
            <Panel header="Phrases" key="1">
              <EditableList
                items={selectedSignal?.phrases || []}
                setItems={(newItems) =>
                  setSelectedSignal({ ...selectedSignal, phrases: newItems })
                }
                placeholder="Phrase"
                disabled={false}
              />
            </Panel>
          </Collapse>
        </Form.Item>
      </Form>
    </Modal>
  );
};
