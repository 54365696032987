import React, { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { getColumns } from "./Dashboard.utils";
import { Button, Checkbox, Col, Drawer, Input, message, Row } from "antd";
import { useLocation } from "react-router-dom";

interface ConversationTableProps {
  data: any[];
  columns?: any[];
  showRisk: boolean;
  sx?: React.CSSProperties;
  handleRowClick?: (record: any) => void;
  pageSize?: number;
  enablePagination?: boolean;
}

export const ConversationTable: React.FC<ConversationTableProps> = ({
  data,
  columns = null,
  showRisk = true,
  sx = {},
  handleRowClick,
  pageSize = 15,
  enablePagination = true,
}) => {
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.selectedColumn) {
      message.info(
        `Showing data regarding ${location.state.selectedColumn}.`,
        3,
      );
    }
  }, [location?.state?.selectedColumns]);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    location?.state?.selectedColumn ? [location.state.selectedColumn] : [],
  );
  const [searchText, setSearchText] = useState("");

  columns = !columns && data?.length > 0 ? getColumns(data, showRisk) : columns;
  const defaultColumns = columns.filter((col) => !col.isDynamic); // Assuming isDynamic is a flag for analysis columns
  const analysisColumns = columns.filter((col) => col.isDynamic);

  const displayedColumns = [
    ...defaultColumns,
    ...analysisColumns.filter((col) => selectedColumns.includes(col.header)),
  ];

  const initialState = {
    columnFilters: displayedColumns
      .filter((col) => col.initialState?.filterValue)
      .map((col) => ({
        id: col.id || col.header,
        value: col.initialState.filterValue,
      })),
    // showColumnFilters: location?.state?.selectedColumns,
  };

  const handleColumnSelectionChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };
  const filteredColumns = analysisColumns.filter(
    (col) =>
      col.header && col.header.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <>
      <Drawer
        title="Select Analysis Columns"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        getContainer={false} // Ensures the Drawer is placed in the document flow
      >
        <Input
          placeholder="Search columns..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        <Checkbox.Group
          options={filteredColumns.map((col) => ({
            label: col.header,
            value: col.header,
          }))}
          value={selectedColumns}
          onChange={handleColumnSelectionChange}
        />
      </Drawer>
      <MaterialReactTable
        enableStickyHeader
        columns={displayedColumns}
        data={data || []}
        enableRowSelection={false}
        enableTopToolbar={true}
        enablePagination={enablePagination}
        onPaginationChange={enablePagination ? setPagination : undefined}
        onSortingChange={setSorting}
        rowCount={data?.length || 0}
        renderTopToolbar={() => (
          <Row justify="end" style={{ width: "100%" }}>
            <Col>
              <Button
                style={{ width: "32px" }}
                onClick={() => setDrawerVisible(true)}
              >
                +
              </Button>
            </Col>
          </Row>
        )}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            boxShadow: "0",
          },
        }}
        state={{
          ...(enablePagination && {
            pagination,
          }),
          sorting,
          // showColumnFilters: selectedColumns?.length > 0,
        }}
        initialState={initialState}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            handleRowClick(row.original);
          },
          sx: { cursor: "pointer", boxShadow: "0 0 0 0!important", ...sx },
        })}
      />
    </>
  );
};
