import React from "react";
import { Space, Button, Dropdown } from "antd";
import {
  EllipsisOutlined,
  TagsOutlined,
  SmileOutlined,
  FileTextOutlined,
  BookOutlined,
  OrderedListOutlined,
  FormOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

import { ChatMessage } from "src/utils/types";
import { Citation } from "./Citations";

export const formatTrucoResponse = (
  text: string,
  citations: Citation[] = null,
  hide: boolean = false,
): ChatMessage => {
  const response = {
    text,
    name: "Truco",
    email: "support@truco.ai",
    photoURL: process.env.PUBLIC_URL + "/favicon.ico",
    time: new Date().toISOString(),
    hide: hide,
    citations: citations,
  };

  return response;
};

enum TRUCO_CHAT_ACTIONS {
  actionItems = "Action Items",
  followUp = "Personalized Follow Up Email",
  detailedSummary = "Detailed Meeting Summary",
  customerFeedback = "Generate Knowledge Article",
  nextSteps = "Outline Next Steps",
  createProposal = "Create Proposal Based on Discussion",
  linkedinPost = "Generate a Linkedin Post",
}

const TRUCO_CHAT_PROMPTS = {
  [TRUCO_CHAT_ACTIONS.actionItems]:
    "Based on the meeting transcript, identify and list all action items discussed. For each action item, include: 1) A clear, concise description of the task, 2) The person or team responsible for completing it, 3) Any mentioned deadlines or time frames. Present these in a bulleted list format, organized by priority if possible.",
  [TRUCO_CHAT_ACTIONS.followUp]:
    "Craft a personalized follow-up email to the customer. Include: 1) A brief thank you for their time, 2) A concise summary of the key points discussed in the meeting, 3) A clear restatement of any commitments made by our team, 4) Any specific next steps or actions the customer needs to take, 5) An invitation for further questions or clarifications, and 6) A professional closing. Ensure the tone is friendly yet professional, and tailored to the customer's communication style observed in the meeting.",
  [TRUCO_CHAT_ACTIONS.detailedSummary]:
    "Create a comprehensive summary of the meeting. Include: 1) Date, time, and attendees, 2) Main agenda items discussed, 3) Key decisions made, 4) Important points of discussion for each topic, 5) Any challenges or concerns raised, 6) Solutions proposed or agreed upon, 7) Action items (cross-referenced with the action items list), 8) Any follow-up meetings scheduled. Organize this information in a clear, easy-to-read format with headings and subheadings.",
  [TRUCO_CHAT_ACTIONS.customerFeedback]:
    "Based on the customer's feedback and questions during the meeting, create a knowledge article that addresses their main points of interest or concern. The article should: 1) Identify the core topic or issue, 2) Provide a clear and concise explanation, 3) Include any relevant technical details or specifications, 4) Address common questions or misconceptions, 5) Offer troubleshooting steps if applicable, 6) Suggest best practices or tips, 7) Include references to official documentation or resources. Ensure the language is clear, avoiding jargon unless necessary, and structure the article for easy readability.",
  [TRUCO_CHAT_ACTIONS.nextSteps]:
    "Outline the next steps following this meeting. Include: 1) Immediate actions to be taken (within the next 48 hours), 2) Short-term objectives (to be completed within 1-2 weeks), 3) Long-term goals discussed in the meeting, 4) Any dependencies or prerequisites for these steps, 5) Potential challenges or risks to consider, 6) Resources needed to accomplish these steps, 7) Suggestions for follow-up communications or meetings. Present this information in a clear, chronological order, with timeframes for each step.",
  [TRUCO_CHAT_ACTIONS.createProposal]:
    "Develop a preliminary proposal based on the discussion in the meeting. The proposal should include: 1) An executive summary highlighting the client's needs and our proposed solution, 2) Detailed description of the products/services discussed, including how they address the client's specific requirements, 3) Timeline for implementation or delivery, 4) Pricing structure or cost estimates, 5) Expected outcomes or benefits for the client, 6) Any assumptions or prerequisites, 7) Next steps to move forward. Format the proposal professionally, using clear headings and bullet points where appropriate. Ensure all information accurately reflects the discussion in the meeting.",
  [TRUCO_CHAT_ACTIONS.linkedinPost]:
    "Write like a Professional LinkedIn Content Creator with 15+ years of experience. Generate LinkedIn Post in English from the above conversation transcript. " +
    "Create a compelling LinkedIn Post that drives engagement. " +
    "Please use related emojis. Also, try to engage the audience in the post + try something that other users want to comment on the post. " +
    "Try to keep Lines short and engaging. " +
    "Don't use more than 4-5 sentences in full content, if exceeded due to long content try to summarize and fit into 4-5 sentence max. " +
    "Also, use best practices for LinkedIn SEO and try to meet all requirements. " +
    "Make sure that content is 100% Original and Plagiarism free. " +
    "Use bullet points in the middle and keep it short and concise. " +
    "Create a call to action to read more and create a hyperlink to the image. " +
    "After that Also give 6 related hashtags for LinkedIn.",
};

export const TrucoChatActions = ({ sendInstruction }) => {
  const onNewInstruction = (instruction) => {
    sendInstruction &&
      sendInstruction(
        formatTrucoResponse(TRUCO_CHAT_PROMPTS?.[instruction], null, true),
      );
  };

  const actionOptions = [
    {
      key: "0",
      icon: <LinkedinOutlined />,
      label: TRUCO_CHAT_ACTIONS.linkedinPost,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.linkedinPost),
    },
    {
      key: "1",
      icon: <SmileOutlined />,
      label: TRUCO_CHAT_ACTIONS.followUp,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.followUp),
    },
    {
      key: "2",
      icon: <TagsOutlined />,
      label: TRUCO_CHAT_ACTIONS.actionItems,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.actionItems),
    },
    {
      key: "3",
      icon: <FileTextOutlined />,
      label: TRUCO_CHAT_ACTIONS.detailedSummary,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.detailedSummary),
    },
    {
      key: "4",
      icon: <BookOutlined />,
      label: TRUCO_CHAT_ACTIONS.customerFeedback,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.customerFeedback),
    },
    {
      key: "5",
      icon: <OrderedListOutlined />,
      label: TRUCO_CHAT_ACTIONS.nextSteps,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.nextSteps),
    },
    {
      key: "6",
      icon: <FormOutlined />,
      label: TRUCO_CHAT_ACTIONS.createProposal,
      onClick: () => onNewInstruction(TRUCO_CHAT_ACTIONS.createProposal),
    },
  ];

  return (
    <Space wrap>
      {actionOptions.slice(0, 2).map((option) => (
        <Button
          icon={option.icon}
          onClick={() => onNewInstruction(option.label)}
        >
          {option.label}
        </Button>
      ))}
      <Dropdown
        menu={{ items: actionOptions.slice(2) }}
        placement="bottomRight"
      >
        <Button icon={<EllipsisOutlined />} />
      </Dropdown>
    </Space>
  );
};
