import { Space, Typography } from "antd";
import { InfoItem } from "./EvaluationInformation.styles";
import { MobileOnly } from "src/styles/stylingComponents";
import { CloseOutlined } from "@ant-design/icons";
import { SpaceBetweenDiv } from "src/components/Profile/Profile.styles";

const { Title, Text } = Typography;

export interface EvaluationInformationType {
  value: string;
  tip: string;
}

export const evaluationInformationDetails: EvaluationInformationType[] = [
  {
    value: "Title",
    tip: "User friendly title to tag your conversations. Ex: Outgoing sales, Incoming calls",
  },
  {
    value: "Knowledge",
    tip: "Explain use case and add knowledge. Ex: Assess customer service proficiency in initiating a cold outreach call. CSR is a customer service representative.",
  },
  {
    value: "Settings",
    tip: "Activate settings, per evaluation. Enabling a setting will allow you to evaluate every conversation based on setting conditions.",
  },
  {
    value: "Category",
    tip: "User friendly title for your category. A category, like Customer Experience could encompasses metrics like Empathy and Tone.",
  },
  {
    value: "Metric",
    tip: "User friendly title for your metric. The metric name, in the above example would be Empathy",
  },
  {
    value: "Category Weight",
    tip: "Define importance of category in overall score. By default, all categories have a uniform weight of 1.",
  },
];

export const signalInformationDetails: EvaluationInformationType[] = [
  {
    value: "Title",
    tip: "Create a concise, user-friendly title for your signal. Ex: Competitor Mentions, Product Demo Requests",
  },
  {
    value: "Description",
    tip: "Provide a clear explanation of the signal's purpose and use case. Ex: This signal is triggered when a customer expresses dissatisfaction or frustration during a call, allowing for immediate escalation to a senior support representative.",
  },
  {
    value: "Keywords",
    tip: "List related signals or terms to improve searchability and categorization. Ex: cancel, refund, sucks",
  },
  {
    value: "Phrases",
    tip: "List of phrases",
  },
];

export const EvaluationInformation = ({
  toggleMobileSidebar,
  information = evaluationInformationDetails,
}) => {
  return (
    <div>
      <SpaceBetweenDiv>
        <Typography.Title level={4}>Information</Typography.Title>

        <MobileOnly>
          <Typography.Title level={5}>
            <CloseOutlined onClick={toggleMobileSidebar} />
          </Typography.Title>
        </MobileOnly>
      </SpaceBetweenDiv>
      {information.map((information, index) => (
        <InfoItem key={`${information.value}-${index}`}>
          <Space direction="vertical" size={0}>
            <Text strong>{information.value}</Text>
            <Text type="secondary">{information.tip}</Text>
          </Space>
        </InfoItem>
      ))}
    </div>
  );
};
