import React from "react";
import {
  Modal,
  Row,
  Space,
  Input,
  Button,
  message,
  ConfigProvider,
} from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { color, elementSize } from "src/styles/variables";

const ShareLinkModal = ({ shareUrl }) => {
  return Modal.success({
    title: "Clip successfully added!",
    content: (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: color.orange,
          },
        }}
      >
        <div>
          <p>You can share the link below:</p>
          <Row>
            <Space.Compact style={{ width: "100%", marginTop: elementSize.sm }}>
              <Input value={shareUrl} />
              <Button
                type="primary"
                icon={<LinkOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(shareUrl).then(() => {
                    message.success("Link Copied to clipboard");
                  });
                }}
              >
                Copy Link
              </Button>
            </Space.Compact>
          </Row>
        </div>
      </ConfigProvider>
    ),
    closable: true,
    footer: null,
    width: 600,
  });
};

export default ShareLinkModal;
