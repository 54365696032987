import React, { useState } from "react";
import {
  CloseOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import {
  Space,
  Row,
  Col,
  Card,
  Typography,
  Popconfirm,
  Tooltip,
  notification,
  Divider,
  Input,
  Dropdown,
  MenuProps,
} from "antd";
import _ from "lodash";

import { color } from "src/styles/variables";

import { humanize, isReviewerInCalibration } from "src/utils/utils";
import { AnalysisContent } from "../AnalysisContent/AnalysisContent";
import {
  updateTranscriptToDb,
  handleFeedbackUpdateToDB,
  markFeedbackAsReadToDB,
  getConversationScopes,
  checkOrganizationAndScope,
} from "../../Dashboard.utils";
import { VideoAudioTranscriptDisplay } from "./VideoAudioTranscriptDisplay";

import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { EditableIcon, SaveIcon } from "../../Dashboard.styles";
import useFetchFileUrl from "src/hooks/useFetchFileUrl";
import { IntegrationDataCard } from "./IntegrationsDataCard";
import { CONVERSATION_SUBMODAL } from "../ConversationModalContent";
import { SHARE_SCOPE } from "../../Dashboard";

const { Paragraph } = Typography;

interface ModalContentBodyProps {
  modalData: any;
  setModalData: any;
  handleClose: any;
  setCurrentSubModal?: (value: CONVERSATION_SUBMODAL) => void;
}

export const ConversationModalBody = ({
  modalData,
  setModalData,
  handleClose,
  setCurrentSubModal,
}: ModalContentBodyProps) => {
  const {
    analysis,
    utterances,
    holds,
    evaluation,
    metadata,
    id,
    customer,
    calibration,
    mimeType,
    ...restData
  } = modalData;

  const metadataKeys = ["summary", "created_at"];
  const excludedCombinedMetaDataKeys = ["customer", "participants"]; // Add the types you want to exclude
  const { organization, setTranscripts, organizationSettings } =
    useOrganizationState();
  const conversationScopes = getConversationScopes().scopeDetails;
  const viewOrganizationProperties = checkOrganizationAndScope(
    restData.organizationId,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_ORGANIZATION_PROPERTIES,
  );
  const viewIntegrationProperties = checkOrganizationAndScope(
    restData.organizationId,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_INTEGRATION_DETAILS,
  );

  const { user } = useUserState();
  const { url } = useFetchFileUrl(id);
  // const navigate = useNavigate();
  const editPermission = modalData?.reviewer?.email === user?.email;

  const conversationPropertiesObject =
    organizationSettings.conversationProperties.reduce((obj, key) => {
      obj[key] = null; // Set each property in the object to null
      return obj;
    }, {});
  const [combinedMetaData, setCombinedMetaData] = useState({
    ...conversationPropertiesObject,
    ...metadata,
    customer: customer,
  });

  const [editedMetaData, setEditedMetaData] = useState({
    ...conversationPropertiesObject,
    ...metadata,
    customer: customer,
  });
  const [startEditMetaData, setStartEditMetaData] = useState(false);

  const handleEvaluation = (evaluationKey, evaluationValue) => {
    const isCalibrator = isReviewerInCalibration(calibration, user);
    evaluation[evaluationKey] = evaluationValue;
    let updatesToSave = {
      evaluation: evaluation,
    };
    if (isCalibrator) {
      calibration["details"][user.email] = {
        ...updatesToSave,
        analysis: analysis,
      };
      const calibratorUpdates = { calibration };
      updateTranscriptToDb(organization, modalData.id, calibratorUpdates);
      setModalData((prevData) => ({
        ...prevData,
        ...updatesToSave,
        ...calibratorUpdates,
      }));
    } else {
      updateTranscriptToDb(organization, modalData.id, updatesToSave);
      setModalData((prevData) => ({
        ...prevData,
        ...updatesToSave,
      }));
    }
  };

  const handleFeedbackUpdate = (
    message,
    allMessages = null,
    setMessages = null,
    setTyping = null,
    mentions?,
  ) => {
    handleFeedbackUpdateToDB(
      message,
      evaluation,
      organization,
      modalData?.id,
      user,
      calibration,
      mentions,
    );
    return null;
  };

  const handleDelete = () => {
    updateTranscriptToDb(organization, id, {
      "transcript_data.metadata.deleted": true,
    });
    setTranscripts((prevTranscripts) =>
      prevTranscripts.filter((transcript) => transcript.id !== id),
    );
    handleClose();
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <PlusOutlined />,
      label: "Add to playlist",
      onClick: () =>
        setCurrentSubModal?.(CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST),
    },
    {
      key: "2",
      icon: <ContentCutIcon />,
      label: "Clip",
      onClick: () => setCurrentSubModal?.(CONVERSATION_SUBMODAL.CLIP),
    },
    {
      key: "3",
      icon: <ShareAltOutlined />,
      label: "Share",
      onClick: () => setCurrentSubModal?.(CONVERSATION_SUBMODAL.SHARE),
    },
    editPermission && {
      key: "4",
      icon: <DeleteOutlined />,
      label: (
        <Popconfirm
          title="Delete conversation"
          description="Are you sure you want to delete this conversation?"
          onConfirm={handleDelete}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          Delete conversation
        </Popconfirm>
      ),
    },
  ];

  const modalTools = () => {
    return (
      <Space
        style={{
          justifyContent: "flex-end",
          display: "flex",
          marginBottom: "10px",
        }}
      >
        {organization === modalData.organizationId && (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <MoreOutlined />
          </Dropdown>
        )}

        <Tooltip title="Close Modal">
          <CloseOutlined style={{ color: color.black }} onClick={handleClose} />
        </Tooltip>
      </Space>
    );
  };

  const handleMetaDataInputChange = (key, value) => {
    setEditedMetaData({
      ...editedMetaData,
      [key]: value === "" ? null : value,
    });
  };

  const handleSaveMetaDataInput = async () => {
    // @todo so ugly
    const { customer: newCustomer, ...restMetadata } = editedMetaData;
    const updates = {
      "metadata.metadata": restMetadata,
      "metadata.customer": newCustomer, // todo
    };
    setCombinedMetaData(editedMetaData);
    updateTranscriptToDb(organization, id, updates);
    notification.success({
      message: "Updated!",
      description: "Details updated successfully!",
      duration: 2,
    });
  };
  // const FetchCustomerID = async (customerValue) => {
  //   const customer = customers?.data?.find(
  //     (data) =>
  //       data.customer === customerValue ||
  //       data.phone === customerValue ||
  //       data.email === customerValue
  //   );
  //   if (customer) {
  //     navigate(`?customer=${encodeURIComponent(customer.id)}`);
  //   } else {
  //     const customerData = await getCustomerInfo(customerValue, organization);
  //     navigate(`?customer=${encodeURIComponent(customerData.id)}`);
  //   }
  // };
  return (
    <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
      {modalTools()}
      <Row gutter={[16, 32]}>
        <Col xl={{ span: 16 }} xs={24}>
          <Space direction="vertical">
            <VideoAudioTranscriptDisplay
              url={url}
              mimeType={mimeType}
              key={id + "-videoAudio"}
              fileId={id}
              utterances={utterances}
              holds={holds}
              handleFeedbackUpdate={handleFeedbackUpdate}
              conversationOrganization={restData.organizationId}
            />
            <Card>
              {metadataKeys.map((dataType, index) => (
                <div key={index}>
                  <Paragraph key={dataType + "-key"}>
                    <strong>{humanize(dataType)}:</strong>
                  </Paragraph>
                  <Paragraph>{restData?.[dataType] || "None"}</Paragraph>
                </div>
              ))}

              {viewOrganizationProperties && (
                <>
                  <Divider />
                  {editPermission && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      onClick={() => setStartEditMetaData(!startEditMetaData)}
                    >
                      {startEditMetaData ? (
                        !_.isEqual(combinedMetaData, editedMetaData) && (
                          <SaveIcon onClick={() => handleSaveMetaDataInput()} />
                        )
                      ) : (
                        <EditableIcon />
                      )}
                    </div>
                  )}
                  {Object.keys(combinedMetaData).length > 0 &&
                    Object.entries(combinedMetaData).map(
                      ([dataType, dataValue]) => {
                        if (excludedCombinedMetaDataKeys.includes(dataType)) {
                          return null; // Skip rendering for excluded types
                        }

                        return (
                          <React.Fragment key={dataType + "-key"}>
                            <Paragraph
                              style={{
                                marginBottom: "8px",
                              }}
                              key={dataType + "-key"}
                            >
                              {dataType === "customer" ? (
                                <></>
                              ) : (
                                <strong>{humanize(dataType)}:</strong>
                              )}
                            </Paragraph>

                            {!startEditMetaData && (
                              <Paragraph>
                                {!dataValue
                                  ? "N/A"
                                  : typeof dataValue === "object"
                                    ? JSON.stringify(dataValue)
                                    : dataValue.toString()}
                              </Paragraph>
                            )}

                            {startEditMetaData && (
                              <Input
                                value={editedMetaData[dataType] || ""}
                                onChange={(e) =>
                                  handleMetaDataInputChange(
                                    dataType,
                                    e.target.value,
                                  )
                                }
                                style={{
                                  width: "50%",
                                  marginBottom: "8px",
                                }}
                              />
                            )}
                          </React.Fragment>
                        );
                      },
                    )}
                </>
              )}
            </Card>
            {modalData.integration_data && viewIntegrationProperties && (
              <IntegrationDataCard modalData={modalData} />
            )}
          </Space>
        </Col>
        <Col xl={{ span: 8 }} xs={24}>
          <AnalysisContent
            modalData={modalData}
            setModalData={setModalData}
            handleEvaluation={handleEvaluation}
            handleFeedbackUpdate={handleFeedbackUpdate}
            markAsRead={() =>
              markFeedbackAsReadToDB(
                user.email,
                evaluation,
                organization,
                modalData?.id,
              )
            }
          />
        </Col>
      </Row>
    </div>
  );
};
