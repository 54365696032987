import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Space, Input, Tag, Tooltip } from "antd";
import { color } from "src/styles/variables";
import { StyledBorderedTag } from "./global.styles";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";

const tagInputStyle: React.CSSProperties = {
  width: 64,
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: "top",
  borderColor: color.orange,
  boxShadow: "none",
};

export const AddRemoveTags = ({
  tags,
  onChange,
  tagType = "team",
  disabled = false,
  ...props
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    onChange(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      onChange([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    onChange(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedTags = Array.from(tags);
    const [removed] = reorderedTags.splice(result.source.index, 1);
    reorderedTags.splice(result.destination.index, 0, removed);

    onChange(reorderedTags);
  };

  const tagPlusStyle: React.CSSProperties = {
    borderStyle: "dashed",
    borderColor: color.orange,
    color: color.orange,
    background: "none",
  };

  const renderTags = () => {
    return tags.map((tag, index) => {
      if (editInputIndex === index) {
        return (
          <Input
            ref={editInputRef}
            key={tag}
            size="small"
            style={tagInputStyle}
            value={editInputValue}
            onChange={handleEditInputChange}
            onBlur={handleEditInputConfirm}
            onPressEnter={handleEditInputConfirm}
          />
        );
      }

      const isLongTag = tag.length > 20;
      const tagElem = (
        <StyledBorderedTag
          key={tag}
          closable={!disabled}
          style={{ userSelect: "none" }}
          onClose={() => handleClose(tag)}
        >
          <span
            onDoubleClick={(e) => {
              if (index !== 0) {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }
            }}
          >
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </span>
        </StyledBorderedTag>
      );

      return isLongTag ? (
        <Tooltip title={tag} key={tag}>
          {tagElem}
        </Tooltip>
      ) : (
        tagElem
      );
    });
  };

  return (
    <Space wrap {...props}>
      {tagType === "stage" ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tags" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  gap: 8,
                }}
              >
                {renderTags().map((tagElem, index) => (
                  <Draggable
                    key={tags[index]}
                    draggableId={tags[index]}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {tagElem}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        renderTags()
      )}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : !disabled ? (
        <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
          New {tagType}
        </Tag>
      ) : null}
    </Space>
  );
};
