import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spin, message, Dropdown, Space, Typography } from "antd";
import { MoreOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  PlaylistTitle,
  PlayListTitleHolder,
  PlayListDetails,
  ClipList,
  ClipItem,
  ClipActions,
  DragHandle,
  ClipPreview,
} from "./PlaylistDetails.styles";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { color, elementSize, grayBoxShadow } from "src/styles/variables";
import { getRecordFromFireStore, updateDocInFireStore } from "src/firebaseAuth";
import { playlistConverter } from "src/utils/converter";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import Thumbnail from "./Thumbnail/Thumbnail"; // Import the Thumbnail component
import { fetchClipUrl, hasPlaylistAccess } from "../Playlist.utils";

import { DeskTopOnly, MobileOnly } from "src/styles/stylingComponents";
import { capitalizeFirstLetter, decryptId, formatTime } from "src/utils/utils";
import { spacing } from "src/styles/variables";

import { ACCESS_TYPE, COLLECTION_DATA, PLAYLIST_TYPE } from "src/utils/enums";
import { PlayList } from "src/utils/types";
import { AddRemoveTags, DeleteWithPopConfirm } from "src/components/Global";
import { PlaylistMenu } from "./PlaylistMenu";

const PlaylistDetails = () => {
  const { encryptedOrganizationId, encryptedPlaylistId } = useParams();
  const organizationId = decryptId(encryptedOrganizationId);
  const playlistId = decryptId(encryptedPlaylistId);

  const navigate = useNavigate();
  const [playlist, setPlaylist] = useState<PlayList | null>(null);

  const [loading, setLoading] = useState(true);
  const { organization, playlists, setPlaylists } = useOrganizationState();
  const { user } = useUserState();
  const [accessDenied, setAccessDenied] = useState(false);

  const hasAccess = hasPlaylistAccess(user, organizationId, playlist);
  const clipsWithAccess = playlist?.clips
    ?.filter(
      (clip) =>
        hasAccess ||
        playlist.type !== PLAYLIST_TYPE.SIGNAL ||
        clip.participants?.includes(user?.email),
    )
    .sort((a, b) => b.order - a.order);

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        const existingPlaylist = playlists?.data?.find(
          (p) => p.id === playlistId,
        );
        if (existingPlaylist) {
          setPlaylist(existingPlaylist);
        } else {
          const doc = await getRecordFromFireStore(
            `/organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`,
            playlistConverter,
          );

          if (doc) {
            const hasDocAccess = hasPlaylistAccess(user, organizationId, doc);
            if (!hasDocAccess) {
              setAccessDenied(true);
              setLoading(false);
              return;
            }
          }
          setPlaylist(doc);
        }
        setLoading(false);
      } catch (error) {
        message.error("Failed to load playlist. Please try again.");
        console.error("Error fetching playlist: ", error);
        setLoading(false);
      }
    };

    fetchPlaylist();
  }, [playlistId, organizationId, playlists, user?.email]);

  useEffect(() => {
    const fetchAndUpdatePlaylistClips = async () => {
      if (!playlist) return;

      for (const clip of playlist?.clips) {
        if (clip?.url) continue;

        const updatedClip = await fetchClipUrl(clip);
        setPlaylist((prevState) => ({
          ...prevState,
          clips: prevState?.clips?.map((c) =>
            c.id === clip.id ? updatedClip : c,
          ),
        }));
      }
    };

    fetchAndUpdatePlaylistClips();
  }, [playlist]);

  const updatePlaylistWithNewClips = async (newClips) => {
    const updatedPlaylist: PlayList = {
      ...playlist,
      clips: newClips.map((clip, index) => ({
        ...clip,
        updatedAt: new Date().toISOString(),
        order: index + 1,
      })),
    };

    setPlaylist(updatedPlaylist);

    setPlaylists((prevPlaylists) => {
      if (!prevPlaylists || !prevPlaylists.data) {
        // Early return if prevPlaylists or prevPlaylists.data is undefined
        return prevPlaylists;
      }

      // Proceed with updating the playlist if prevPlaylists and prevPlaylists.data exist
      return {
        ...prevPlaylists,
        data: prevPlaylists.data.map((p) =>
          p.id === playlist.id ? updatedPlaylist : p,
        ),
      };
    });

    try {
      await updateDocInFireStore(
        `/organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`,
        updatedPlaylist,
        playlistConverter,
      );
      message.success("Playlist order updated.");
    } catch (error) {
      message.error("Failed to update playlist order.");
      console.error("Error updating playlist order: ", error);
    }
  };
  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedClips = Array.from(playlist.clips);

    const sourceIndex = reorderedClips.findIndex(
      (clip) => clip.id === clipsWithAccess[result.source.index].id,
    );
    const destinationIndex = reorderedClips.findIndex(
      (clip) => clip.id === clipsWithAccess[result.destination.index].id,
    );

    const [removed] = reorderedClips.splice(sourceIndex, 1);
    reorderedClips.splice(destinationIndex, 0, removed);

    await updatePlaylistWithNewClips(reorderedClips);
  };

  const handleDeleteClip = async (clipIndex) => {
    const updatedClips = playlist.clips.filter(
      (_, index) => index !== clipIndex,
    );
    await updatePlaylistWithNewClips(updatedClips);
  };

  const handleClipClick = async (clip) => {
    navigate(`?clip=${encodeURIComponent(clip.id)}`);
  };

  if (loading) return <Spin spinning={loading} />;

  if (accessDenied)
    return <div>You do not have access to view this playlist.</div>;

  if (!playlist) return <div>Playlist not found.</div>;
  const commonDropDown = () => {
    return (
      <Dropdown
        overlay={
          <PlaylistMenu
            playlistId={playlist.id}
            setLoading={setLoading}
            redirectToPlaylist={true}
            linkToCopy={`${window.location.href}`}
          />
        }
        trigger={["click"]}
        overlayStyle={{ boxShadow: grayBoxShadow }}
      >
        <MoreOutlined
          style={{
            cursor: "pointer",
            fontSize: "20px",
            color: color.orange,
            transform: "rotate(90deg)",
          }}
        />
      </Dropdown>
    );
  };
  return (
    <div
      style={{
        padding: elementSize.sm,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PlayListTitleHolder>
        <MobileOnly
          style={{
            alignSelf: "flex-end",
            marginLeft: "auto",
            backgroundColor: color.grayLight,
            padding: elementSize.xxs,
            borderRadius: "50%",
          }}
        >
          {commonDropDown()}
        </MobileOnly>

        <PlayListDetails
          style={{
            padding: elementSize.xxs,
            marginBottom: elementSize.xs,
          }}
        >
          <PlaylistTitle>{playlist?.title}</PlaylistTitle>
          <div style={{ color: color.grayMedium }}>{playlist.description}</div>
          <div style={{ color: color.grayMedium }}>
            {clipsWithAccess?.length} Clips
          </div>
        </PlayListDetails>

        <DeskTopOnly
          style={{
            alignSelf: "flex-end",
            marginLeft: "auto",
            backgroundColor: color.grayLight,
            padding: elementSize.xxs,
            borderRadius: "50%",
          }}
        >
          {commonDropDown()}
        </DeskTopOnly>
      </PlayListTitleHolder>
      <div style={{ flex: 1 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="clips"
            isDropDisabled={organizationId !== organization}
          >
            {(provided) => (
              <ClipList ref={provided.innerRef} {...provided.droppableProps}>
                {clipsWithAccess.map((clip, index) => (
                  <Draggable
                    key={`${clip.id}-${clip.conversationId}`}
                    draggableId={`${clip.id}-${clip.conversationId}`}
                    index={index}
                    isDragDisabled={organizationId !== organization}
                  >
                    {(provided) => (
                      <ClipItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => handleClipClick(clip)}
                      >
                        <ClipPreview
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleClipClick(clip);
                          }}
                        >
                          {clip.mimeType.includes("text") ? (
                            <ClipPreview>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <FileTextOutlined
                                  style={{ fontSize: "24px" }}
                                />
                              </div>
                            </ClipPreview>
                          ) : (
                            <ClipPreview
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleClipClick(clip);
                              }}
                            >
                              <Thumbnail
                                url={clip.url}
                                start={clip.start}
                                end={clip.end}
                                type={clip.mimeType}
                                clipId={`${clip.id}-${clip.conversationId}`}
                              />
                            </ClipPreview>
                          )}
                        </ClipPreview>
                        <Space
                          direction="vertical"
                          style={{ paddingLeft: spacing.md, flexGrow: 1 }}
                        >
                          <Typography.Text>
                            {clip.title && capitalizeFirstLetter(clip.title)}
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            {clip?.summary || ""}
                          </Typography.Text>
                          {clip?.tags?.length > 0 && (
                            <AddRemoveTags
                              onChange={null}
                              tagType={"Signal Tags"}
                              tags={clip.tags}
                              disabled={true}
                            />
                          )}
                          <div
                            style={{
                              color: color.grayMedium,
                              paddingTop: elementSize.xxs,
                            }}
                          >
                            {clip?.timeline?.length > 0
                              ? `${clip?.timeline.length} Clips`
                              : formatTime(clip.end - clip.start)}
                          </div>
                        </Space>
                        <ClipActions>
                          {(organizationId === organization ||
                            playlist?.createdBy === user?.email) && (
                            <DragHandle>☰</DragHandle>
                          )}

                          {DeleteWithPopConfirm(
                            "Delete Condition?",
                            "Are you sure you want to delete this clip? ",
                            (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteClip(index);
                            },
                            clip.user !== user?.email ||
                              user.accessType === ACCESS_TYPE.agent ||
                              user.accessType === ACCESS_TYPE.lead,
                          )}
                        </ClipActions>
                      </ClipItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ClipList>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default PlaylistDetails;
