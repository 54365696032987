import { Typography, Button } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { color, elementSize } from "src/styles/variables";

export const ErrorBack = ({
  title,
  routingName,
  routingUrl = null,
  onClick = null,
}) => {
  return (
    <div
      style={{
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography.Title level={2}>
        {`This ${title} doesn't exist :(`}
      </Typography.Title>
      <Typography.Title level={4}>
        <NavLink
          style={{ color: color.orange }}
          to={routingUrl}
          onClick={onClick}
        >
          {`← Go back to ${routingName}`}
        </NavLink>
      </Typography.Title>
    </div>
  );
};

export const NavigateBack = ({ url }) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<ArrowLeftOutlined />}
      onClick={() => navigate(url)}
      shape="circle"
      style={{
        backgroundColor: color.grayLight,
        border: "none",
        marginRight: elementSize.xs,
      }}
    />
  );
};
