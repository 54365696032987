import { StandardPage } from "../Home";

import { useOrganizationState } from "src/state/OrganizationState";

import { CommonForm } from "./CommonForm";
import { FORM_TYPE } from "../Profile/Profile.enums";
import { useParams } from "react-router-dom";
import { EvaluationFormType } from "src/utils/types";
import {
  createNewEvaluationForm,
  validateEvaluationFields,
} from "./Evaluation.utils";
import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Button, Card, Col, Row, Spin, message } from "antd";
import { elementSize } from "src/styles/variables";
import { SANDBOX_TABS } from "../TrucoSandbox/TrucoSandbox.utils";
import { UploadPlayground } from "../TrucoSandbox/UploadPlayground";
import { CaretRightFilled } from "@ant-design/icons";
import { useModal } from "src/state/UseModal";
import { StyledAudioCard } from "../TrucoSandbox/TrucoSandbox.styles";
import { MODAL_TYPES } from "src/utils/enums";
import { processTrial } from "../DragDropFiles/DragDropFiles.utils";
import { useUserState } from "src/state/UserState";
import { transformToTranscript } from "src/utils/converter";

export const Evaluation = () => {
  const { openModal } = useModal();
  const { evaluationForms, setEvaluationForms } = useOrganizationState();
  const { id } = useParams();
  const isEditMode = id != null; // true if 'id' is present in the URL
  const [showTestCard, setShowTestCard] = useState(false);
  const [activeTab, setActiveTab] = useState(SANDBOX_TABS.SAMPLE_FILE);
  const [uploadedFile, setUploadFile] = useState([]);
  const [loadingRun, setLoadingRun] = useState(false);
  const [transcript, setTranscript] = useState(null);
  const { user } = useUserState();

  const [selectedEvaluation, setSelectedEvaluation] =
    useState<EvaluationFormType>(() => {
      return isEditMode && evaluationForms && evaluationForms?.[id]
        ? evaluationForms[id]
        : createNewEvaluationForm();
    });

  const [currentSelectedEvaluation, setCurrentSelectedItem] =
    useState<EvaluationFormType>(() => cloneDeep(selectedEvaluation));

  let disableRun =
    (activeTab === SANDBOX_TABS.SAMPLE_FILE && uploadedFile.length === 0) ||
    (activeTab === SANDBOX_TABS.OWN_FILE &&
      (uploadedFile.length === 0 ||
        (uploadedFile.length > 0 && uploadedFile[0].status))) ||
    !validateEvaluationFields(
      currentSelectedEvaluation,
      currentSelectedEvaluation?.analysisStructures,
    );

  const runProcessTrial = async () => {
    setLoadingRun(true);
    try {
      const res: { transcript: any; trialsLeft: number } = await processTrial(
        uploadedFile[0],
        user?.email,
        currentSelectedEvaluation,
      );
      setTranscript(transformToTranscript(res.transcript));
      setLoadingRun(false);
    } catch (err) {
      console.log(err.response.data);
      message.error("Transcript failed to generate");

      setLoadingRun(false);
    }
  };

  const evaluationContent = (
    <>
      {showTestCard && (
        <Row
          style={{
            width: "91%",
            margin: `${elementSize.sm} auto ${elementSize.sm} `,
            display: "flex",
            alignItems: "stretch",
          }}
          gutter={[16, 16]}
        >
          <Col xs={24} md={18}>
            <Card style={{ height: "100%" }}>
              <UploadPlayground
                showTrials={false}
                uploadedFile={uploadedFile}
                setUploadFile={setUploadFile}
                loadingRun={loadingRun}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setTranscript={setTranscript}
              />
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card
              title="Evaluation Result"
              extra={
                <Button
                  disabled={disableRun || loadingRun}
                  onClick={runProcessTrial}
                >
                  Run <CaretRightFilled />
                </Button>
              }
              style={{ height: "100%" }}
            >
              <Spin spinning={loadingRun}>
                {transcript && uploadedFile.length > 0 && (
                  <StyledAudioCard
                    style={{
                      width: "100%",
                      flex: 1,
                    }}
                    id={transcript.id}
                    onClick={() => {
                      openModal(
                        MODAL_TYPES.CONVERSATION,
                        transcript.id,
                        transcript,
                      );
                    }}
                  >
                    <p>{transcript.file_name}</p>
                  </StyledAudioCard>
                )}
              </Spin>
            </Card>
          </Col>
        </Row>
      )}

      <CommonForm
        existingData={evaluationForms}
        type={FORM_TYPE.EVALUATION}
        setExistingData={setEvaluationForms}
        selectedItem={selectedEvaluation}
        setSelectedItem={setSelectedEvaluation}
        currentSelectedItem={currentSelectedEvaluation}
        setCurrentSelectedItem={setCurrentSelectedItem}
        showTestCard={showTestCard}
        setShowTestCard={setShowTestCard}
      />
    </>
  );

  return <StandardPage content={evaluationContent} />;
};
