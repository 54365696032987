import React from "react";
import { Menu, Dropdown, Spin, Select } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import UnsubscribeManager from "src/utils/UnsubscribeManager";
import { CollectAllExistingOrganizations } from "src/hooks/CollectOrganizationData";
import { useLocation } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CollaboratorAvatar } from "../Global";
import { useUserState } from "src/state/UserState";
import { SelectContainer, StyledDropdownButton } from "./Filters.style";

export const OrganizationSelect = ({ type = "sidebar" }) => {
  const {
    organizationSettings,
    setOrganization,
    setCustomers,
    setTranscripts,
    setInsights,
    setCollaborators,
    setOrganizationSettings,
    setPendingCollaborators,
    setEvaluationForms,
    setPlaylists,
    allExistingOrganizations,
  } = useOrganizationState();
  const { user } = useUserState();

  if (!allExistingOrganizations) {
    CollectAllExistingOrganizations();
  }
  const location = useLocation();

  const organizationClear = () => {
    UnsubscribeManager.getInstance().clearUnsubs();
    location.state = null;
    setTranscripts(null);
    setCustomers({ data: null, hasMore: false, lastDoc: null });
    setCollaborators(null);
    setPendingCollaborators(null);
    setEvaluationForms(null);
    setOrganizationSettings(null);
    setInsights(null);
    setPlaylists(null);
  };

  const handleMenuClick = (selectedOption) => {
    organizationClear();
    setOrganization(selectedOption.key);
  };

  const handleSelectClick = (selectedOption) => {
    organizationClear();
    setOrganization(selectedOption);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {allExistingOrganizations?.map((existingOrg) => (
        <Menu.Item key={existingOrg.id}>{existingOrg.name}</Menu.Item>
      ))}
    </Menu>
  );
  const options = allExistingOrganizations?.map((existingOrgs) => ({
    label: existingOrgs.name,
    value: existingOrgs.id,
  }));

  return (
    <>
      {type === "profile" ? (
        <Select
          placeholder={organizationSettings?.name}
          style={{ width: 150 }}
          onChange={handleSelectClick}
          options={options}
        />
      ) : (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          disabled={!organizationSettings?.name}
        >
          <StyledDropdownButton
            disabled={!organizationSettings?.name}
            icon={
              <CollaboratorAvatar
                style={{
                  width: 18,
                  height: 18,
                }}
                collaborator={user}
              />
            }
          >
            <SelectContainer>
              {organizationSettings?.name || <Spin />}
              {organizationSettings?.name && (
                <ExpandMoreIcon
                  style={{
                    fontSize: 16,
                  }}
                />
              )}
            </SelectContainer>
          </StyledDropdownButton>
        </Dropdown>
      )}
    </>
  );
};
