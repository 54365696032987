import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Tooltip,
  Spin,
  Modal,
  ConfigProvider,
  message,
} from "antd";
import {
  EllipsisOutlined,
  ShareAltOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  getRecordsFromFireStore,
  hardDeleteDocInFireStore,
} from "src/firebaseAuth";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { useUserState } from "src/state/UserState";
import { documentId } from "firebase/firestore";
import { useOrganizationState } from "src/state/OrganizationState";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { PlaylistTitle } from "./Playlist.styles";
import ShareLinkModal from "../Dashboard/ConversationModal/ShareConversation/ShareClipPopUp";
import { useNavigate } from "react-router-dom";
import { fetchFileUrl } from "../Dashboard/Dashboard.utils";
import { color, elementSize } from "src/styles/variables";

const MediaCard = ({ clip }) => {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      if (clip.mimeType.includes("video")) {
        try {
          const url = await fetchFileUrl(clip.conversationId); // Fetching video URL
          setVideoUrl(`${url}#t=${Math.floor(clip.start + 2)}`);
        } catch (error) {
          console.error("Error fetching file URL for video: ", error);
          setVideoUrl("/playlist.jpeg"); // Fallback in case of an error
        }
      }
    };

    fetchVideoUrl();
  }, [clip.conversationId, clip.mimeType, clip.start]);

  const cardStyle: React.CSSProperties = {
    width: "95%",
    height: "120px",
    objectFit: "cover" as "cover", // Explicitly typing objectFit
    borderRadius: "8px",
    overflow: "hidden",
  };

  if (clip.mimeType.includes("audio")) {
    return <img src="/playlist.jpeg" alt="Audio" style={cardStyle} />;
  }

  if (clip.mimeType.includes("video")) {
    return videoUrl ? (
      <video src={videoUrl} style={cardStyle} />
    ) : (
      <div style={cardStyle}>Loading...</div>
    );
  }

  if (clip.mimeType.includes("text")) {
    return <img src="/text_thumbnail.jpg" alt="Text" style={cardStyle} />;
  }

  return <img src="/playlist.jpeg" alt="Default" style={cardStyle} />;
};

const ClipsCard = () => {
  const [clips, setClips] = useState([]);
  const [loading, setLoading] = useState(false);
  const { organization } = useOrganizationState();
  const navigate = useNavigate();

  const { user } = useUserState();

  useEffect(() => {
    const fetchClips = async () => {
      setLoading(true);
      const data = (
        await getRecordsFromFireStore(`/${COLLECTION_DATA.CLIPS}`, [
          {
            comparisonField: "user",
            value: user.email,
            comparisonOperator: "==",
          },
          {
            comparisonField: documentId(),
            order: "desc",
          },
        ])
      ).data;
      console.log("data", data);

      setClips(data);
      setLoading(false);
    };

    fetchClips();
  }, [organization]);

  const handleCardClick = (clipId) => {
    navigate(
      `/playlist?${QUERY_PARAMS.CLIP}=${clipId}&${QUERY_PARAMS.SHAREABLE_CLIP}=true`,
    );
  };
  const showDeleteModal = (clipId) => {
    ConfigProvider.config({
      theme: {
        token: {
          colorPrimary: color.orange,
        },
      },
    });

    Modal.confirm({
      title: "Confirm Deletion",
      content: (
        <div>
          <p>Are you sure you want to delete this clip permanently?</p>
        </div>
      ),
      onOk: async () => {
        try {
          // Delete clip from Firestore
          await hardDeleteDocInFireStore(`/${COLLECTION_DATA.CLIPS}/${clipId}`);
          message.success("Clip deleted successfully!");

          // Update clips state by filtering out the deleted clip
          setClips((prevClips) => prevClips.filter((c) => c.id !== clipId));
        } catch (error) {
          message.error("Failed to delete clip.");
          console.error("Error deleting clip: ", error);
        }
      },
      okButtonProps: {
        style: { backgroundColor: color.orange },
      },
    });
  };

  const renderShareMenu = (clipId) => (
    <Menu>
      <Menu.Item
        key="1"
        icon={<ShareAltOutlined />}
        onClick={() => {
          const shareUrl = `${window.location.origin}/playlist?${QUERY_PARAMS.CLIP}=${clipId}&${QUERY_PARAMS.SHAREABLE_CLIP}=true`;
          ShareLinkModal({ shareUrl });
        }}
      >
        Share
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<DeleteOutlined />}
        onClick={(info) => {
          info.domEvent.preventDefault();
          info.domEvent.stopPropagation();
          showDeleteModal(clipId);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        {clips?.map((clip) => (
          <Col key={clip.id} xs={12} sm={12} md={8} lg={6} xl={4}>
            <div onClick={() => handleCardClick(clip.id)}>
              {/* {renderMediaImage(clip)} */}
              <MediaCard clip={clip} />

              <SpaceBetweenDiv>
                <Tooltip title={clip.title}>
                  <PlaylistTitle style={{ fontSize: "15px" }}>
                    {clip.title}
                  </PlaylistTitle>
                </Tooltip>
                <Dropdown
                  overlay={renderShareMenu(clip.id)}
                  trigger={["click"]}
                >
                  <EllipsisOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    style={{
                      float: "right",
                      marginLeft: elementSize.sm,
                      marginRight: elementSize.sm,
                    }}
                  />
                </Dropdown>
              </SpaceBetweenDiv>
            </div>
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default ClipsCard;
