import { Empty, List, Tooltip } from "antd";
import React, { useState } from "react";
import { PlaylistPlay } from "@mui/icons-material";
import { WifiOutlined } from "@ant-design/icons";
import { color } from "src/styles/variables";
import { generatePlaylistShareableLink } from "src/components/Playlist/Playlist.utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { capitalizeFirstLetter } from "src/utils/utils";

export const PlaylistAndSignals = ({ playlists }) => {
  const { organization } = useOrganizationState();
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);

  return playlists && playlists.length > 0 ? (
    <List
      dataSource={
        playlists as Array<{ id: string; title: string; summary?: string }>
      }
      renderItem={(playlist) => (
        <List.Item
          onMouseEnter={() => setHoveredItemId(playlist.id)}
          onMouseLeave={() => setHoveredItemId(null)}
          onClick={() => {
            window.open(
              `${window.location.origin}${generatePlaylistShareableLink(
                organization,
                playlist.id,
              )}`,
              "_blank",
            );
          }}
        >
          <List.Item.Meta
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            avatar={
              <Tooltip
                title={
                  playlist.summary ? "Signal Playlist" : "Regular Playlist"
                }
              >
                {playlist.summary ? (
                  <WifiOutlined style={{ color: color.orange, fontSize: 22 }} />
                ) : (
                  <PlaylistPlay style={{ color: color.orange, fontSize: 24 }} />
                )}
              </Tooltip>
            }
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <a
                  href={`${window.location.origin}${generatePlaylistShareableLink(
                    organization,
                    playlist.id,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color:
                      hoveredItemId === playlist.id ? color.orange : "inherit",
                    transition: "color 0.3s ease",
                  }}
                >
                  {capitalizeFirstLetter(playlist.title)}
                </a>
              </div>
            }
            description={
              playlist.summary && (
                <Tooltip title={playlist.summary}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {playlist.summary}
                    </span>
                  </div>
                </Tooltip>
              )
            }
          />
        </List.Item>
      )}
    />
  ) : (
    <Empty description="No playlists or signals exist" />
  );
};
