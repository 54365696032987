import {
  borderRadius,
  color,
  spacing,
  fontSize,
  elementSize,
  deviceSize,
  fontWeight,
  grayBoxShadow,
} from "src/styles/variables";
import styled from "styled-components";
import { Table, Collapse, Card } from "antd";

export const EvaluationSubTitle = styled.div`
  fontsize: ${fontSize.bodyLarge};
  font-weight: ${fontWeight.semiBold};
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXXL}; // Adjust as needed
  }
`;
// Main container for the card layout
export const CardLayoutContainer = styled.div`
  display: ${(props) => props.sidebar && "flex"};
  width: 90%;
  border-radius: ${borderRadius.xs};
  overflow: hidden;
  border: 1px solid ${color.grayLight};
  @media (max-width: ${deviceSize.large_phone}) {
    width: 92%;
  }
`;

// White content section of the card
export const ContentSection = styled.div`
  flex: 3.5;
  background: ${color.white};
  min-width: 0;
  padding-bottom: ${elementSize.ml};

  // @media (max-width: ${deviceSize.large_phone}) {
  //   display: ${(props) => (props.mobileShow ? "none" : "flex")};
  // }
`;

export const SidebarSection = styled.div`
  flex: ${(props) => (props.show ? 1 : 0)};
  min-width: ${(props) => (props.show ? "none" : 0)};
  height: ${(props) => (props.show ? "none" : 0)};

  background: ${color.grayLight};
  padding: 0px ${spacing.ml} ${spacing.ml} ${spacing.lg};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out;
  transform: translateX(${(props) => (props.show ? "0%" : "100%")});
  opacity: ${(props) => (props.show ? 1 : 0)};

  @media (max-width: ${deviceSize.large_phone}) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(${(props) => (props.mobileShow ? "0" : "100%")});
    z-index: 100; /* Make sure it's above other content */
    padding-top: 20px; /* Provide space for close icon */
    border-radius: 20px 20px 0 0; /* Add rounded corners to the top */
    overflow-y: auto; /* Allow scrolling within the sidebar */
    transition: transform 0.5s ease-out; /* Smooth transition for sliding in */
    box-shadow: ${grayBoxShadow};
  }
`;

// Footer area for buttons
export const SidebarFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px; /* Space above the buttons */
`;

export const TitleUnderline = styled.div`
  border-bottom: 1px solid ${color.grayLight};
`;

export const EvaluationRulesTable = styled(Table)`
  border: 1px solid ${color.grayLight};
  border-radius: ${borderRadius.xs} ${borderRadius.xs} 0px 0px;
  margin: ${elementSize.sm} 0px;
  &&:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td {
    align-content: flex-start;
  }
`;

export const AddText = styled.div`
  margin-bottom: ${elementSize.sm};
  font-size: ${fontSize.body};
  color: ${color.orange};
  display: ${(props) => (props.disabled ? "none" : "block")};

  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXL}; // Adjust as needed
  }
`;

export const TranscriptBox = styled.div`
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 5px;
`;

export const AddOptions = styled(Collapse)`
  background: none;
  border: 0;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  .ant-collapse-header {
    color: ${color.orange} !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: ${fontSize.body};
    @media (max-width: ${deviceSize.large_phone}) {
      font-size: ${fontSize.displayXL}; // Adjust as needed
    }
  }
  .ant-collapse-expand-icon {
    padding-inline-end: ${spacing.xxxs} !important;
    @media (max-width: ${deviceSize.large_phone}) {
      padding-inline-end: ${spacing.xs} !important;
    }
  }

  &&.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-left: 0px;
  }
`;

export const PresetsScrollableContainer = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin-top: ${elementSize.lg};
`;

export const EvaluationPresetCategoryCard = styled(Card)`
  width: 100%;
  margin-bottom: 16px;
  background-color: ${(props) =>
    props.added && color.grayLight}; // Change color if added
  .ant-card-meta-title {
    color: ${color.orange};
  }
  .ant-card-body {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-card-meta-description {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${color.black};
  }

  .ant-card-actions > li {
    background-color: ${(props) =>
      props.added && color.grayLight}; // Change color if added

    margin: 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    background-color: ${(props) =>
      props.added && color.grayLight}; // Change color if added
  }
`;
export const PhrasesPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    padding: 0 !important;
  }
`;

export const FormContainer = styled.div`
  margin-top: ${elementSize.lg};
`;
