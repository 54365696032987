import React, { useState } from "react";
import {
  NavigationBarContainer,
  SignInContainer,
  HeaderContentContainer,
  NavMenu,
  StyledMenuIcon,
  StyledCloseIcon,
  CustomizedMenu,
  NavMenuMobileContainer,
  DescriptionMenuText,
  NavMenuDeskTopContainer,
  ConversationIcon,
  MessengerIconNavBar,
  CustomizedBadge,
} from "./NavigationBar.styles";

import { type MenuProps } from "antd";
import { useUserState } from "src/state/UserState";
import {
  DesktopSignOut,
  SignInButton,
  handleSignOut,
  CollaboratorAvatar,
  DivLink,
} from "src/components";
import TrucoLogo from "../TrucoLogo/TrucoLogo";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Logout,
  ManageAccounts,
  EmojiObjects,
  Payments,
} from "@mui/icons-material";

import { UserNavBarAvatar } from "../User/User.styles";
import { color } from "src/styles/variables";
import { getOptions } from "src/utils/utils";
import { COLLECTION_DATA } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import { QueryConditions } from "src/utils/types";
import { notDeletedConversations } from "src/utils/variables";
import { useCollection } from "src/hooks/useCollection";

interface NavigationBarProps {
  headercolor?: string;
}

enum MENU_ITEMS {
  RESOURCES = "resources",
  SOLUTIONS = "solutions",
  PROFILE = "profile",
  MESSENGER = "messenger",
  LOGOUT = "logout",
  PRICING = "pricing",
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  headercolor = null,
}) => {
  const { user } = useUserState();
  const { organization } = useOrganizationState();

  const [openMobileMenuBar, setOpenMobileMenuBar] = useState(false);
  const [openDesktopUserMenu, setOpenDesktopUserMenu] = useState(false);
  const [unReadMsgs, setUnReadMsgs] = useState(0);
  const [loadingLogout, setloadingLogout] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleIconClick = () => {
    setOpenMobileMenuBar(!openMobileMenuBar);
  };

  let unreadByAgentQuery: QueryConditions = null;
  if (user?.email) {
    unreadByAgentQuery = {
      comparisonField: "evaluation.unreadBy",
      comparisonOperator: "array-contains",
      value: user.email,
    };
  }
  const queryConditions = [notDeletedConversations, unreadByAgentQuery];

  useCollection(
    organization
      ? `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}`
      : undefined,
    setUnReadMsgs,
    undefined,
    queryConditions,
    undefined,
    undefined,
    true,
  );
  const menuOptionFunction = (item) => ({
    label: <a href={item?.url}>{item?.title}</a>,
    key: item.title.toLowerCase().replace(/\s+/g, ""),
  });

  const menuItems: MenuProps["items"] = [
    {
      key: MENU_ITEMS.RESOURCES,
      label: "Resources",
      children: getOptions(
        [
          {
            url: "/ergeon-case-study",
            title: "Customer Stories",
          },
          {
            url: "/playlist/U2FsdGVkX19pvvm99ocSsOAr1GyR5%2Bt%2Bst2FRp9z0cDqlndu80GMmyguJMz6rQv3/U2FsdGVkX1%2Fpt3jfIX8DEtKe0vpH3SieG0fRp%2FRQ9BkYfuwHdQjrMO%2Fi3OB611y8",
            title: "Leader Lens Podcast",
          },
          {
            url: "/blogs",
            title: "Blogs",
          },
          {
            url: "/roi",
            title: "ROI Calculator",
          },
          {
            url: process.env.REACT_APP_TRUCO_DOCS,
            title: "Knowledge Base",
          },
        ],
        menuOptionFunction,
        false,
      ),
    },
    {
      key: MENU_ITEMS.SOLUTIONS,
      label: "Solutions",
      children: getOptions(
        [
          {
            url: "/",
            title: "Auto QA",
          },
          {
            url: "/",
            title: "Customer Insights",
          },
          {
            url: "/",
            title: "Coaching and Enablement",
          },
        ],
        menuOptionFunction,
        false,
      ),
    },
    !user && {
      key: MENU_ITEMS.PRICING,
      ...menuOptionFunction({
        url: "/pricing",
        title: "Pricing",
      }),
    },
  ].filter(Boolean);
  let mobileMenuItems = [...menuItems];
  mobileMenuItems = mobileMenuItems.map((item) => ({
    ...item,
    icon:
      item.key === MENU_ITEMS.RESOURCES ? (
        <ManageAccounts />
      ) : item.key === MENU_ITEMS.PRICING ? (
        <Payments />
      ) : (
        <EmojiObjects />
      ),
  }));

  let loggedInUserMenuItems: MenuProps["items"] = [
    {
      key: MENU_ITEMS.PROFILE,
      icon: (
        <CollaboratorAvatar
          key={user?.name}
          collaborator={user}
          style={{
            backgroundColor: color.white,
            color: color.olive,
            marginLeft: "-5px",
          }}
          AvatarComponent={UserNavBarAvatar}
        />
      ),
      label: (
        <DivLink
          onClick={() => {
            if (user?.organization) navigate("/profile");
          }}
        >
          <div>
            {user?.name ||
              user?.email?.split("@")[0].substring(0, 10) ||
              "User"}

            <DescriptionMenuText>Show my profile</DescriptionMenuText>
          </div>
        </DivLink>
      ),
    },
    {
      key: MENU_ITEMS.LOGOUT,
      icon: <Logout />,
      label: (
        <DivLink
          onClick={() =>
            !loadingLogout && handleSignOut(navigate, setloadingLogout)
          }
          style={{ pointerEvents: loadingLogout ? "none" : "auto" }}
        >
          {loadingLogout ? `Logging out...` : `Logout`}
        </DivLink>
      ),
    },
  ];

  if (user) {
    mobileMenuItems.unshift(
      loggedInUserMenuItems.find((item) => item.key === MENU_ITEMS.PROFILE),
    );

    mobileMenuItems.push(
      loggedInUserMenuItems.find((item) => item.key === MENU_ITEMS.LOGOUT),
    );
  }

  return (
    <>
      <HeaderContentContainer headercolor={headercolor}>
        <NavigationBarContainer>
          <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
            <TrucoLogo />
          </div>
        </NavigationBarContainer>
        <NavMenu
          mode="horizontal"
          items={menuItems}
          headercolor={headercolor}
        />

        <SignInContainer>
          {user ? (
            <>
              {user?.organization !== null &&
                !location.pathname.includes(MENU_ITEMS.MESSENGER) && (
                  <CustomizedBadge count={unReadMsgs}>
                    <MessengerIconNavBar
                      onClick={() => navigate(`/${MENU_ITEMS.MESSENGER}`)}
                      headercolor={headercolor}
                    >
                      <ConversationIcon />
                    </MessengerIconNavBar>
                  </CustomizedBadge>
                )}
              <DesktopSignOut
                setOpenDesktopUserMenu={setOpenDesktopUserMenu}
                openDesktopUserMenu={openDesktopUserMenu}
                headercolor={headercolor}
              />
            </>
          ) : (
            <SignInButton />
          )}
          <StyledMenuIcon
            visible={!openMobileMenuBar}
            onClick={handleIconClick}
          />
          <StyledCloseIcon
            visible={openMobileMenuBar}
            onClick={handleIconClick}
          />
        </SignInContainer>
      </HeaderContentContainer>

      {openMobileMenuBar && (
        <NavMenuMobileContainer>
          <CustomizedMenu
            mode="inline"
            items={mobileMenuItems}
            defaultOpenKeys={!user && ["resources", "solutions"]}
            headercolor={headercolor}
          />
        </NavMenuMobileContainer>
      )}
      {openDesktopUserMenu && (
        <NavMenuDeskTopContainer>
          <CustomizedMenu
            mode="inline"
            items={loggedInUserMenuItems}
            headercolor={headercolor}
          />
        </NavMenuDeskTopContainer>
      )}
    </>
  );
};
